import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card, CardBody, Col,
    Container, Form,
    Input, Label, Row
} from "reactstrap"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"

//redux

const EditCategory = props => {
  const { id } = useParams()
  const [categoryName, setCategoryName] = useState(null)
  const history = useHistory()

  // /api/course/category/:category_id

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/category/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCategoryName(data?.message?.category_name)
      })
      .catch(err => console.log(err))
  }, [id])

  const handleSubmit = async e => {
    e.preventDefault()
    await fetch(
      `${process.env.REACT_APP_URL}/api/update-course/category/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ course_category: categoryName }),
      }
    )
      .then(res => res.json())
      .then(data => {
        if (data?.success) {
          history.push("/category")
        }
      })
      .catch(err => console.log(err))
    setCategoryName("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Category")}
            breadcrumbItem={props.t("Add new category")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Category Name
                            </Label>
                            <Input
                              value={categoryName}
                              onChange={e => setCategoryName(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Computer Science"
                            />
                          </div>
                          <div>
                            <button
                              disabled={categoryName ? false : true}
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Save
                            </button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditCategory)
