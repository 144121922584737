import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import { Col, Container, Row, Table, Button } from "reactstrap"
import "./course.scss"

import Item from "./Item"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"

const CourseList = () => {
  const [courseList, setCourseList] = useState([])
  const [status, setStatus] = useState(false)

  const history = useHistory()

  // Get all courses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCourseList(data?.message)
        setStatus(false)
      })
      .catch(err => console.log(err?.response?.data))
  }, [status])

  // delete specific course

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Courses" breadcrumbItem="Course List" />
          <Button
            onClick={() => history.push(`/courses/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Course
          </Button>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "100px" }}>
                          #
                        </th>
                        <th scope="col">Course Name</th>
                        <th scope="col">Updated Date</th>
                        <th scope="col">Total Enrolled</th>
                        {/* <th scope="col">Instructor Name</th> */}
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(courseList, (course, index) => (
                        <Item
                          setStatus={setStatus}
                          course={course}
                          key={index}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CourseList)
