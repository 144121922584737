import React, { useState, useEffect } from "react"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "./Quiz.css"

const Quiz = () => {
  const [questions, setquestions] = useState([
    { question: "", a: "", b: "", c: "", d: "", answer: "" },
  ])

  const [courseList, setCourseList] = useState([])
  const [status, setStatus] = useState(true)
  const [courseTitle, setCourseTitle] = useState("")
  const [examDuration, setExamDuration] = useState("")
  const [fullMarks, setFullMarks] = useState("")
  const [passMarks, setPassMarks] = useState("")
  const [coursePreviousData, setCoursePreviousData] = useState(null)
  const [quizPassPercentage, setQuizPassPercentage] = useState("")

  // Get all courses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCourseList(data?.message)
      })
      .catch(err => console.log(err?.response?.data))
  }, [status])

  const handleCourseChange = e => {
    setCourseTitle(e.target.value)

    setquestions([{ question: "", a: "", b: "", c: "", d: "", answer: "" }])

    setStatus(false)
    fetch(
      `${process.env.REACT_APP_URL}/api/course/quiz/admin/get/${e.target.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(res => res.json())
      .then(data => {
        setCoursePreviousData(data?.data)
      })
      .catch(err => console.log(err?.response?.data))
  }

  useEffect(() => {
    console.log(coursePreviousData?.quiz?.length)
    if (coursePreviousData?.quiz?.length > 0) {
      coursePreviousData?.quiz?.map((pd, index) => {
        let preQuestion = {}
        preQuestion["question"] = pd?.question
        preQuestion["a"] = pd?.a
        preQuestion["b"] = pd?.b
        preQuestion["c"] = pd?.c
        preQuestion["d"] = pd?.d
        preQuestion["answer"] = pd?.answer

        // questions[index].push(preQuestion)
        questions.splice(index, 0, preQuestion)
        setStatus(true)
      })
    } else {
      setquestions([{ question: "", a: "", b: "", c: "", d: "", answer: "" }])
      setStatus(true)
    }
  }, [coursePreviousData])

  console.log(questions)

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...questions]
    list[index][name] = value
    setquestions(list)
  }

  const handleRemoveClick = index => {
    const list = [...questions]
    list.splice(index, 1)
    setquestions(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setquestions([
      ...questions,
      {
        question: "",
        a: "",
        b: "",
        c: "",
        d: "",
        answer: "",
      },
    ])
  }

  const handleSubmit = e => {
    e.preventDefault()
    // const data = []
    // data["quiz"] = [...questions]
    // data["courseTitle"] = courseTitle || coursePreviousData?.courseTitle
    // data["quizTotalMarks"] = fullMarks || coursePreviousData?.quizTotalMarks
    // data["quizPassMarks"] = passMarks || coursePreviousData?.quizPassMarks
    // data["quizDuration"] = examDuration || coursePreviousData?.quizDuration

    // console.log("data", data)

    const body = {
      quiz: [...questions],
      courseTitle: courseTitle ? courseTitle : coursePreviousData?.courseTitle,
      quizTotalMarks: fullMarks
        ? fullMarks
        : coursePreviousData?.quizTotalMarks,
      quizPassMarks: passMarks ? passMarks : coursePreviousData?.quizPassMarks,
      quizDuration: examDuration
        ? examDuration
        : coursePreviousData?.quizDuration,
      quizPassPercentage: quizPassPercentage
        ? quizPassPercentage
        : coursePreviousData?.quizPassPercentage
        ? coursePreviousData?.quizPassPercentage
        : 40,
    }

    fetch(
      `${process.env.REACT_APP_URL}/api/course/quiz/update/${courseTitle}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Quiz")
          history.push("/quiz")
        } else {
          toastr.error(data?.message, "Quiz")
        }
      })
      .catch(err => toastr.error(err?.response?.data?.message, "Quiz"))
  }

  return (
    <div className="quizDiv">
      <p className="quizHeading">Quiz</p>
      <div className="quizForm">
        <Form>
          <FormGroup>
            <Label for="SelectCourseTitle">Course Title</Label>
            <Input
              name="courseTitle"
              type="select"
              onChange={e => handleCourseChange(e)}
            >
              {courseList?.map(course => {
                return (
                  <>
                    <option key={course._id} value="" hidden>
                      Select
                    </option>
                    <option value={course._id}>{course.title}</option>
                  </>
                )
              })}
            </Input>
          </FormGroup>
        </Form>

        {status ? (
          <Form>
            <FormGroup>
              <Label className="mt-3" for="SelectCourseTitle">
                Exam Duration
              </Label>
              <Input
                name="examDuration"
                placeholder={
                  coursePreviousData?.quizDuration
                    ? coursePreviousData?.quizDuration
                    : "Exam Duration"
                }
                onChange={e => setExamDuration(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="SelectCourseTitle">
                Total Marks
              </Label>
              <Input
                name="totalMarks"
                placeholder={
                  coursePreviousData?.quizTotalMarks
                    ? coursePreviousData?.quizTotalMarks
                    : "Total Marks"
                }
                onChange={e => setFullMarks(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="SelectCourseTitle">
                Pass Marks
              </Label>
              <Input
                name="passMark"
                placeholder={
                  coursePreviousData?.quizPassMarks
                    ? coursePreviousData?.quizPassMarks
                    : "Pass Mark"
                }
                onChange={e => setPassMarks(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="SelectCourseTitle">
                Pass Percentage
              </Label>
              <Input
                name="quizPassPercentage"
                placeholder={
                  coursePreviousData?.quizPassPercentage
                    ? coursePreviousData?.quizPassPercentage
                    : "Quiz Pass Percentage"
                }
                onChange={e => setQuizPassPercentage(e.target.value)}
              />
            </FormGroup>
          </Form>
        ) : null}

        {status
          ? questions.map((x, i) => {
              return (
                <div key={i} className="box ">
                  {/* <p className="me-3 mt-3 "></p> */}
                  <Form>
                    <FormGroup>
                      <Label className="mt-3 fw-bold" for="Question">
                        Question NO : {i + 1}
                      </Label>
                      <Input
                        name="question"
                        placeholder="question"
                        value={x.question}
                        onChange={e => handleInputChange(e, i)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="mt-3" for="optionA">
                        (A)
                      </Label>
                      <Input
                        name="a"
                        placeholder="Option A"
                        value={x.a}
                        onChange={e => handleInputChange(e, i)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="mt-3" for="optionB">
                        (B)
                      </Label>
                      <Input
                        name="b"
                        placeholder="Option B"
                        value={x.b}
                        onChange={e => handleInputChange(e, i)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="mt-3" for="optionC">
                        (C)
                      </Label>
                      <Input
                        name="c"
                        placeholder="option C"
                        value={x.c}
                        onChange={e => handleInputChange(e, i)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="mt-3" for="optionD">
                        (D)
                      </Label>
                      <Input
                        name="d"
                        placeholder="Option D"
                        value={x.d}
                        onChange={e => handleInputChange(e, i)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="mt-3" for="exampleSelect">
                        Select the right answer
                      </Label>
                      <Input
                        name="answer"
                        onChange={e => handleInputChange(e, i)}
                        type="select"
                      >
                        <option value="" hidden>
                          {x.answer ? x.answer : "Select"}
                        </option>
                        <option value={x?.a}>{x.a}</option>
                        <option value={x?.b}>{x.b}</option>
                        <option value={x?.c}>{x.c}</option>
                        <option value={x?.d}>{x.d}</option>
                      </Input>
                    </FormGroup>
                  </Form>
                  <br />
                  <div className="d-flex justify-content-center">
                    {questions.length !== 1 && (
                      <button
                        // className="deleteButton"
                        // onClick={() => handleRemoveClick(i)}
                        onClick={() =>
                          window.confirm("Do you want to delete this question?")
                            ? handleRemoveClick(i)
                            : null
                        }
                        className="btn btn-danger w-md"
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                        <span>Delete the question</span>
                      </button>
                    )}
                    {questions.length - 1 === i && (
                      <button
                        className="addButton ms-3"
                        onClick={handleAddClick}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                        <span>Add New question</span>
                      </button>
                    )}
                  </div>
                </div>
              )
            })
          : null}
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default Quiz
