import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card, CardBody, Col,
    Container, Form,
    Input, Label, Row
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux

const AddAdmin = props => {
  const [adminEmail, setAdminMail] = useState("")
  const [adminName, setAdminName] = useState("")
  const [adminPassword, setAdminPassword] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    await fetch(`${process.env.REACT_APP_URL}/api/auth/signup/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        fullName: adminName,
        email: adminEmail,
        password: adminPassword,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data?.success) {
          toastr.error(data?.message)
        } else if (data?.success) {
          toastr.success(data?.message)
          setAdminMail("")
          setAdminName("")
          setAdminPassword("")
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Admin")}
            breadcrumbItem={props.t("Add new admin")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Admin Name
                            </Label>
                            <Input
                              value={adminName}
                              onChange={e => setAdminName(e.target.value)}
                              required
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Mr. X"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Admin Email
                            </Label>
                            <Input
                              value={adminEmail}
                              onChange={e => setAdminMail(e.target.value)}
                              type="email"
                              required
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="xyz@gamil.com"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Admin default password
                            </Label>
                            <Input
                              value={adminPassword}
                              onChange={e => setAdminPassword(e.target.value)}
                              type="password"
                              className="form-control"
                              required
                              id="formrow-firstname-Input"
                              placeholder="Ex: 12345"
                            />
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Save
                            </button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AddAdmin)
