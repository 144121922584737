import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//redux
import { useSelector } from "react-redux"

import { Link, useHistory, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action

// import images
import { authResetPassword, forgotPassword } from "actions/auth"
import logo from "../../assets/images/Favicon_Qlearn-01.png"
import profile from "../../assets/images/profile-img.png"

const ForgetPasswordPage = props => {
  const [email, setEmail] = useState(null)
  const [resetPassword, setResetPassword] = useState(false)
  const [status, setStatus] = useState(false)
  const [show, setShow] = useState(false)
  const history = useHistory()

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  function handleValidSubmit(event, data) {
    if (!resetPassword) {
      setStatus(true)
      setEmail(data?.email)

      forgotPassword(data?.email)
        .then(r => r.json())
        .then(res => {
          console.log(res)
          if (res?.success) {
            setStatus(false)
            toastr.success(res?.message)
            setResetPassword(true)
          } else {
            toastr.error(res?.message)
            setStatus(false)
          }
        })
        .catch(err => {
          toastr.error(err?.response?.data)
          setStatus(false)
        })
    } else {
      if (data?.newPassword !== data?.confirmPassword) {
        toastr.error("confirm password is not match")
      } else {
        if (data?.code && data?.newPassword) {
          setStatus(true)
          authResetPassword(email, data?.code, data?.newPassword)
            .then(r => r.json())
            .then(res => {
              if (res?.success) {
                setStatus(false)
                toastr.success(res?.message)
                history.push("/login")
              } else {
                toastr.error(res?.message)
                setStatus(false)
              }
            })
            .catch(err => {
              toastr.error(err?.response?.data)
              setStatus(false)
            })
        }
      }
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Qlearn.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="25"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      {!resetPassword ? (
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="mb-3">
                            <AvField
                              name="code"
                              label="Code"
                              className="form-control"
                              placeholder="Enter code"
                              type="text"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="newPassword"
                              label="New Password"
                              className="form-control"
                              placeholder="Enter new password"
                              type="password"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="confirmPassword"
                              label="Confirm Password"
                              className="form-control"
                              placeholder="Enter confirm password"
                              type="password"
                              required
                            />
                          </div>
                        </div>
                      )}

                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            disabled={status}
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {status ? "Sending..." : "Reset"}
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Qlearn</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
