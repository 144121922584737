import { getSpecificBundleCourse, getSpecificCourse } from "actions/course"
import React, { useEffect, useState } from "react"
import { Tr, Td } from "react-super-responsive-table"
import {
  blockedCourse,
  unBlockedCourse,
  checkCourseBlocked,
} from "../../actions/course"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const CourseCard = ({ userId, course }) => {
  const [courseDetails, setCourseDetails] = useState(null)
  const [block, setBlock] = useState(false)

  useEffect(() => {
    if (course?.bundle) {
      getSpecificBundleCourse(course?._id)
        .then(r => r.json())
        .then(res => {
          console.log(res)
          if (res?.success && res?.message !== "Not found!") {
            setCourseDetails(res?.message)
          }
        })
        .catch(err => console.log(err))
    } else {
      getSpecificCourse(course?._id)
        .then(r => r.json())
        .then(res => {
          if (res?.success && res?.message !== "Not found!") {
            setCourseDetails(res?.message)
          }
        })
        .catch(err => console.log(err))
    }
  }, [course])

  useEffect(() => {
    checkCourseBlocked(course?._id, userId)
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setBlock(res?.message)
        }
      })
      .catch(err => console.log(err))
  }, [])

  //** Blocked Course */
  const handleBlock = () => {
    blockedCourse(course?._id, userId)
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setBlock(true)
          toastr.success(res?.message)
        }
      })
      .catch(err => console.log(err))
  }

  //** Unblocked Course */
  const handleUnBlock = () => {
    unBlockedCourse(course?._id, userId)
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setBlock(false)
          toastr.success(res?.message)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <Tr>
      <Td>
        {courseDetails?.title !== undefined
          ? courseDetails?.title
          : courseDetails?.name}
      </Td>
      <Td>{course?.bundle === false ? "False" : "True"}</Td>
      <Td>
        {block ? (
          <button onClick={handleUnBlock} className="btn btn-primary">
            Unblock
          </button>
        ) : (
          <button onClick={handleBlock} className="btn btn-danger">
            Block
          </button>
        )}
      </Td>
    </Tr>
  )
}

export default CourseCard
