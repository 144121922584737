import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { Container, FormGroup, Input, Label, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumb from "components/Common/Breadcrumb"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
const AffiliatePayment = () => {
  const { affiliateId } = useParams()
  const [transactionId, setTransactionId] = useState(null)
  const [shortDescription, setShortDescription] = useState(null)
  const [textareabadge, settextareabadge] = useState(0)
  const [textcount, settextcount] = useState(0)
  const history = useHistory();

  console.log({ affiliateId })
  function textareachange(event) {
    setShortDescription(event.target.value)
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  const handleAffiliatePay = async () => {
    if (!transactionId) {
      toastr.error("Please Enter transaction Id")
      return
    }

    const payload = {
      transactionId,
      comment: shortDescription,
      paidDate: new Date(),
    }

    console.log({ payload })
    fetch(
      `${process.env.REACT_APP_URL}/api/affiliate/paid/payment/${affiliateId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Affiliate")
            history.push("/affiliateList")
        } else {
            toastr.error(data?.message, "Affiliate")
        }
      })
        .catch(err => toastr.error(err?.response?.data?.message, "Affiliate"))

    // const config = {
    //   "Content-Type": "application/json",
    //   Accept: "application/json",
    // }
    // const response = await axios.post(
    //   `${process.env.REACT_APP_URL}/api/affiliate/paid/payment/${affiliateId}`,
    //   payload,
    //   config
    // )

    // if (response?.data?.success) {
    //   toastr.success(response?.data?.message)
    // }
    // console.log(response)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Our Professor</title>
      </MetaTags>{" "}
      <Container fluid>
        {" "}
        <Breadcrumb title="Affiliate" breadcrumbItem="Affiliate Payment" />
        <div
          style={{
            marginTop: "50px",
            marginLeft: "20px",
            marginRight: "30px",
          }}
        >
          <FormGroup>
            <Label className="mt-3" for="transaction ID">
              Transaction ID
            </Label>
            <Input
              name="transactionId"
              placeholder="Enter the transaction ID"
              onChange={e => setTransactionId(e.target.value)}
            />

            <Row>
              <div className="mt-2 mb-3">
                <Label>Comment</Label>

                <Input
                  required
                  type="textarea"
                  id="textarea"
                  onChange={e => {
                    textareachange(e)
                  }}
                  maxLength="500"
                  rows="3"
                  placeholder="Enter comment if you have any."
                />
                {textareabadge ? (
                  <span className="badgecount badge bg-success">
                    {" "}
                    {textcount} / 500{" "}
                  </span>
                ) : null}
              </div>
            </Row>
          </FormGroup>

          <button
            onClick={() => {
              handleAffiliatePay()
            }}
            color="primary"
            className="btn btn-primary mt-1"
          >
            Pay
          </button>
        </div>
      </Container>{" "}
    </React.Fragment>
  )
}

export default AffiliatePayment
