import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Button, Card,
    CardBody, CardSubtitle, CardTitle, Col, Container,
    Row
} from "reactstrap"

// React router dom
import { useHistory } from "react-router"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { getAdminList } from "actions/auth"
import { withTranslation } from "react-i18next"

const Admins = props => {
  const [admins, setAdmins] = useState([])

  const history = useHistory()

  useEffect(() => {
    getAdminList()
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setAdmins(res?.message)
        }
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Admins")}
            breadcrumbItem={props.t("Admins")}
          />
          <Button
            onClick={() => history.push(`/add-admin`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Admin
          </Button>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>All admins List </CardTitle>
                      <CardSubtitle className="mb-3">
                        total {admins?.length} admins
                      </CardSubtitle>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Full Name</Th>
                                <Th data-priority="1">Email</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {admins?.map(ins => (
                                <Tr>
                                  <Th>
                                    <span className="co-name">
                                      {ins?.fullName}
                                    </span>
                                  </Th>
                                  <Td>{ins?.email}</Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Admins)
