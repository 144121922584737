import { adminLogin } from "actions/auth"
import profile from "assets/images/profile-img.png"
import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const Login = props => {
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  const dispatch = useDispatch()
  const history = useHistory()
  console.log(process.env)
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    adminLogin(values)
      .then(res => res.json())
      .then(data => {
        if (!data?.success) {
          toastr.error(data?.message, "Login")
        } else if (data?.success) {
          toastr.success(data?.message, "Login")
          const authUser = {
            displayName: "Syket",
            email: values?.email,
            uid: "2379879820398",
          }

          localStorage.setItem("authUser", JSON.stringify(authUser))
          history.push("/dashboard")
        }
      })
      .catch(err => console.log(err?.response?.body))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin - Qlearn</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Qlearn admin.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Qlearn</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  history: PropTypes.object,
  adminLogin: PropTypes.func.isRequired,
}
export default connect(null, { adminLogin })(Login)
