import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Button, Card,
    CardBody,
    CardTitle, Col, Container,
    Row
} from "reactstrap"
// React router dom
import { useHistory } from "react-router"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

const Category = props => {
  const history = useHistory()

  const [featuredCourse, setFeaturedCourse] = useState(null)

  console.log({ featuredCourse })

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/featured`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data?.success) {
          setFeaturedCourse(data?.message?.length > 0 ? data?.message[0] : null)
        }
      })
      .catch(err => console.log(err?.response?.data))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dynamic Section")}
            breadcrumbItem={props.t("Dynamic Section")}
          />
          {!featuredCourse && (
            <Button
              onClick={() => history.push(`/featured-courses/add/new`)}
              color="primary"
              className="btn btn-primary "
            >
              Add New dynamic section
            </Button>
          )}

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>Dynamic Section</CardTitle>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter section title"
                          readOnly
                          value={featuredCourse?.featureCourseTitle}
                        />
                      </div>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Dynamic Section</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                {featuredCourse && (
                                  <>
                                    <Th>
                                      <span className="co-name">
                                        {featuredCourse?.course?.map(c => (
                                          <p>{c?.title}</p>
                                        ))}
                                      </span>
                                    </Th>
                                    <Th>
                                      <Link
                                        to={`/featured-courses/edit/${featuredCourse?._id}`}
                                      >
                                        <button className="btn btn-primary w-md">
                                          Edit
                                        </button>
                                      </Link>
                                    </Th>
                                  </>
                                )}
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Category)
