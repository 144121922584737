import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import {
  Card, CardBody, Col,
  Container, Label, Row
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StudentList from "../../components/StudentList"

//i18n
import { withTranslation } from "react-i18next"

//redux

const EnrolledCourse = props => {
  const [instructorEmail, setInstructorEmail] = useState(null)
  const [selectedStudents, setSelectedStudents] = useState([])
  const [selectCourses, setSelectCourses] = useState([])
  const [students, setStudents] = useState([])
  const [courseList, setCourseList] = useState([])
  const [bundleCourseList, setBundleCourseList] = useState([])
  const [finalCourseList, setFinalCourseList] = useState([])
  const [finalCourses, setFinalCourses] = useState([])

  // Get all courses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        data?.message?.map(course => {
          setCourseList(prevData => [
            ...prevData,
            { label: course?.title, value: course?._id },
          ])
        })
        // setCourseList(data?.message)
      })
      .catch(err => console.log(err?.response?.data))
  }, [])

  //** Bundle Course List */
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/bundle/getall`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data?.message?.length > 0) {
          data?.message?.map(course => {
            setBundleCourseList(prevData => [
              ...prevData,
              { label: course?.name, value: course?._id },
            ])
          })
        }
      })
  }, [])

  // useEffect(() => {
  //   getAllStudents()
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data?.success) {
  //         data?.message?.map(st => {
  //           setStudents(prevData => [
  //             ...prevData,
  //             { label: st?.email, value: st?.email },
  //           ])
  //         })
  //       }
  //     })
  // }, [])

  useEffect(() => {
    const oneArray = [courseList, bundleCourseList]
    setFinalCourseList(oneArray.flat())
  }, [courseList, bundleCourseList])

  useEffect(() => {
    const key = "value"
    const arrayUniqueByKey = [
      ...new Map(finalCourseList.map(item => [item[key], item])).values(),
    ]
    setFinalCourses(arrayUniqueByKey)
  }, [finalCourseList])

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  useEffect(() => {
    const key = "value"
    const arrayUniqueByKey = [
      ...new Map(finalCourseList.map(item => [item[key], item])).values(),
    ]
    setFinalCourses(arrayUniqueByKey)
  }, [finalCourseList])
  const studentOptions = [...new Set(students)]

  const handleSelectCourse = data => {
    setSelectCourses(data)
  }

  console.log(selectedStudents)

  const handleSubmit = async e => {
    const studentsEmail = selectedStudents
    const coursesId = []

    selectCourses?.map(course => coursesId.push(course?.value));

    const payload = {
      note: "Test 1",
      amount: 120,
      userId: "61c34a3e104af624c3f88883",
      courseId: "6150cceea3d4617153979cf4",
      transactionId: "123444",
      transactionMethod: "CASH",
      transactionNumber: "123456"
    };

    console.log({ studentsEmail, coursesId });
    await fetch(`${process.env.REACT_APP_URL}/api/manualenrolment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: studentsEmail, course: coursesId }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data?.success) {
          toastr.error(data?.message)
        } else if (data?.success) {
          toastr.success(data?.message)
        }
        console.log(data?.message)
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Manual Enrollment"
            breadcrumbItem="Manual Enrollment"
          />
          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card style={{ height: "100vh" }}>
                      <CardBody>
                        <div className="mb-3">
                          <StudentList
                            selectedStudents={selectedStudents}
                            setSelectedStudents={setSelectedStudents}
                            multi={true}
                            text="Select..."
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Select Courses
                          </Label>
                          <Select
                            required
                            value={selectCourses}
                            isMulti={true}
                            onChange={handleSelectCourse}
                            options={finalCourses}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                        <div>
                          <button
                            disabled={
                              selectCourses?.length > 0 &&
                                selectedStudents?.length > 0
                                ? false
                                : true
                            }
                            onClick={
                              selectCourses?.length > 0 &&
                                selectedStudents?.length > 0
                                ? handleSubmit
                                : null
                            }
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Save
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EnrolledCourse)
