import { getSingleNotices, updateSingleNotices } from "actions/notice"
import FBEditor from "components/Editor/Editor"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux

const EditNotice = props => {
  const { id } = useParams()
  const [noticeDetails, setNoticeDetails] = useState(null)
  const [text, setText] = useState("")
  const [active, setActive] = useState(true)

  const msg = "Already one notice is visible, please turn off the notice first"
  const history = useHistory()

  useEffect(() => {
    getSingleNotices(id)
      .then(r => r.json())
      .then(data => setNoticeDetails(data?.message))
      .catch(err => console.log(err))
  }, [id])

  useEffect(() => {
    setText(noticeDetails?.text ? noticeDetails?.text : "")
    setActive(noticeDetails?.visible)
  }, [noticeDetails])

  const handleSubmit = e => {
    e.preventDefault()

    const body = {
      text,
      visible: active,
    }
    updateSingleNotices(id, body)
      .then(res => res?.json())
      .then(data => {
        if (!noticeDetails?.visible && data?.success && data?.message === msg) {
          toastr.error(data?.message, "Notice")
        } else {
          if (data?.success) {
            toastr.success("Notice updated!!", "Notice")
            history.push("/notice")
          } else {
            toastr.error(data?.message?.message, "Notice")
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleBodyChange = e => {
    setText(e)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Notice")}
            breadcrumbItem={props.t("Edit notice")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Notice Text
                              </Label>
                              <FBEditor
                                htmlData={text}
                                setHtmlData={setText}
                                prevData={
                                  noticeDetails?.text ? noticeDetails?.text : ""
                                }
                              />
                            </div>

                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Active Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="draft"
                                  value="true"
                                  checked={active ? true : false}
                                  onChange={e => setActive(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="draft"
                                >
                                  True
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="Publish"
                                  value="false"
                                  checked={!active ? true : false}
                                  onChange={e => setActive(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  False
                                </label>
                              </div>
                            </div>

                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md mt-3"
                              >
                                Update notice
                              </button>
                            </div>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditNotice.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

EditNotice.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(EditNotice)
