import moment from "moment"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"

// Form Editor

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//redux

const AddNewCoupon = props => {
  const [code, setCode] = useState("")
  const [discount, setDiscount] = useState(0)
  const [amount, setAmount] = useState(0)
  const [expiryDate, setExpiryDate] = useState("")
  const [maxUse, setMaxUse] = useState("")
  const [active, setActive] = useState(true)
  const [maxUsePerPerson, setMaxUsePerPerson] = useState("")
  const [selectCourses, setSelectCourses] = useState([])
  const [courseList, setCourseList] = useState([])
  const [bundleCourseList, setBundleCourseList] = useState([])
  const [finalCourseList, setFinalCourseList] = useState([])
  const [minDate, setMinDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )

  const [uploadStatus, setUploadStatus] = useState(false)
  const history = useHistory()

  // Get all courses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data?.message?.length > 0) {
          data?.message?.map(course => {
            setCourseList(prevData => [
              ...prevData,
              { label: course?.title, value: course?._id },
            ])
          })
        }
        // setCourseList(data?.message)
      })
      .catch(err => console.log(err?.response?.data))
  }, [])

  //** Bundle Course List */
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/bundle/getall`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data?.message?.length > 0) {
          data?.message?.map(course => {
            setBundleCourseList(prevData => [
              ...prevData,
              { label: course?.name, value: course?._id },
            ])
          })
        }
      })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    let courseIdArray = []

    selectCourses?.map(sc => {
      courseIdArray.push(sc?.value)
    })

    const body = {
      code,
      expiry: new Date(expiryDate).toISOString(),
      discount: Number(discount),
      amount: Number(amount),
      maxuse: Number(maxUse),
      maxusePerPerson: Number(maxUsePerPerson),
      course: courseIdArray,
      active,
    }

    fetch(`${process.env.REACT_APP_URL}/api/coupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Coupon")
          history.push("/coupons")
        } else {
          toastr.error(data?.message, "Coupon")
          console.log({ data })
        }
      })
      .catch(err => {
        console.log({ err })
        toastr.error(err?.response?.data?.message, "Coupon")
      })
  }

  //** Make one final course list array */
  useEffect(() => {
    const oneArray = [courseList, bundleCourseList]
    setFinalCourseList(oneArray.flat())
  }, [courseList, bundleCourseList])

  const courseOptions = [...new Set(finalCourseList)]

  const handleSelectCourse = data => {
    setSelectCourses(data)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Coupon")}
            breadcrumbItem={props.t("Add new coupon")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Code
                              </Label>
                              <Input
                                required
                                type="text"
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Coupon code"
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Amount
                              </Label>
                              <Input
                                value={amount}
                                onChange={e => setAmount(e.target.value)}
                                type="number"
                                onWheel={e => e.target.blur()}
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Amount"
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Discount
                              </Label>
                              <Input
                                value={discount}
                                onChange={e => setDiscount(e.target.value)}
                                type="number"
                                onWheel={e => e.target.blur()}
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Discount"
                              />
                            </div>

                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Select courses
                              </Label>
                              <Select
                                required
                                isMulti
                                value={selectCourses}
                                onChange={handleSelectCourse}
                                options={courseOptions}
                                classNamePrefix="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Expiry date
                              </Label>
                              <Input
                                value={expiryDate}
                                onChange={e => setExpiryDate(e.target.value)}
                                required
                                type="date"
                                min={minDate}
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Coupon code"
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Max use
                              </Label>
                              <Input
                                value={maxUse}
                                onChange={e => setMaxUse(e.target.value)}
                                onWheel={e => e.target.blur()}
                                required
                                type="number"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Max use"
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Max use per person
                              </Label>
                              <Input
                                value={maxUsePerPerson}
                                onChange={e =>
                                  setMaxUsePerPerson(e.target.value)
                                }
                                onWheel={e => e.target.blur()}
                                required
                                type="number"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Max use per person"
                              />
                            </div>

                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Active Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="draft"
                                  value="true"
                                  checked={active ? true : false}
                                  onChange={e => setActive(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="draft"
                                >
                                  True
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="Publish"
                                  value="false"
                                  checked={!active ? true : false}
                                  onChange={e => setActive(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  False
                                </label>
                              </div>
                            </div>

                            <div>
                              <button
                                disabled={uploadStatus ? true : false}
                                type="submit"
                                className="btn btn-primary w-md mt-3"
                              >
                                Create coupon
                              </button>
                            </div>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AddNewCoupon)
