import React, { useEffect, useState } from "react"
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "./CreateSeminar.css"
import FBEditor from "components/Editor/Editor"
import { useParams } from "react-router-dom"
import { imageUpload } from "actions/imageUpload"
import Dropzone from "react-dropzone"
const seminarZone = [
  {
    id: 1,
    zone: "সকাল",
  },
  {
    id: 2,
    zone: "দুপুর",
  },
  {
    id: 3,
    zone: "বিকাল",
  },
  {
    id: 4,
    zone: "সন্ধ্যা",
  },
  {
    id: 5,
    zone: "রাত",
  },
]

const SeminarEdit = () => {
  const [details, setDetails] = useState("")
  const [courseId, setCourseId] = useState("")
  const [date, setDate] = useState(null)
  const [year, setYear] = useState(null)
  const [month, setMonth] = useState(null)
  const [title, setTitle] = useState("")
  const [time, setTime] = useState("")
  const [zone, setZone] = useState("")
  const [type, setType] = useState("")
  const [live, setLive] = useState(false)
  const [link, setLink] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [schedule, setSchedule] = useState("")
  const [speaker, setSpeaker] = useState("")
  const [speakerShortInfo, setSpeakerShortInfo] = useState("")
  const { seminarId } = useParams()
  console.log("seminarId", seminarId)

  const [cardImage, setCardImage] = useState(null)
  const [cardImageError, setCardImageError] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)

  function handleAcceptedCardFiles(files) {
    setCardImageError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setCardImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setCardImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Seminar Image")
          } else {
            toastr.error(err?.response?.data?.message, "Seminar Image")
          }
        })
    } else {
      setCardImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }


  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/seminar/get/${seminarId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res?.json())
      .then(data => {

        console.log({ data });
        if (data?.success) {
          setData(data.data)
          setTitle(data.data.title)
          setTime(data.data.time)
          setZone(data.data.zone)
          setType(data.data.type)
          setLink(data.data.link)
          setYear(data.data.year)
          setMonth(data.data.month)
          setDate(data.data.date)
          setDetails(data.data.description)
          setSpeaker(data.data.speaker)
          setSpeakerShortInfo(data.data.speakerShortInfo)
          setSchedule(data.data.dateTime.slice(0, 10))
          setCardImage(data?.data?.cardImage)
        } else {
          console.log(data)
        }
      })
      .catch(err => console.log(err))
  }, [seminarId])

  useEffect(() => { }, [year, month, date])
  const handleDate = e => {
    const data = e.target.value
    console.log(data.slice(0, 4))
    setSchedule(data)
    const toBn = n => n.replace(/\d/g, d => "০১২৩৪৫৬৭৮৯"[d])
    setDate(toBn(data.slice(8)))
    setYear(toBn(data.slice(0, 4)))
    const month = data.slice(5, 7)
    if (month == 1) {
      setMonth("জানুয়ারি")
    } else if (month == 2) {
      setMonth("ফেব্রুয়ারি")
    } else if (month == 3) {
      setMonth("মার্চ")
    } else if (month == 4) {
      setMonth("এপ্রিল")
    } else if (month == 5) {
      setMonth("মে")
    } else if (month == 6) {
      setMonth("জুন")
    } else if (month == 7) {
      setMonth("জুলাই")
    } else if (month == 8) {
      setMonth("আগস্ট")
    } else if (month == 9) {
      setMonth("সেপ্টেম্বর")
    } else if (month == 10) {
      setMonth("অক্টোবর")
    } else if (month == 11) {
      setMonth("নভেম্বর")
    } else if (month == 12) {
      setMonth("ডিসেম্বর")
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)
    const body = {
      title,
      description: details,
      date,
      month,
      year,
      time,
      zone,
      type,
      link,
      live: false,
      dateTime: schedule,
      speaker: speaker,
      speakerShortInfo: speakerShortInfo,
      seminarImage: cardImage ? cardImage : null
    }

    if (
      !title ||
      !schedule ||
      !zone ||
      !type ||
      !time ||
      !zone ||
      !link ||
      !speaker ||
      !speakerShortInfo
    ) {
      alert("All the fields are required")
      setIsLoading(false)
      return
    }
    console.log(body)

    fetch(`${process.env.REACT_APP_URL}/api/seminar/update/${seminarId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res?.json())
      .then(data => {
        setIsLoading(false)
        console.log(data)
        if (data?.success) {
          toastr.success(data?.message, "Seminar")
        } else {
          toastr.error(data?.message, "Seminar")
        }
      })
      .catch(err => toastr.error(err?.response?.data?.message, "Seminar"))
  }

  return (
    <div className="seminar_div">
      <h2 className="seminar_Heading">Seminar Edit</h2>
      {isLoading ? (
        <>
          <i className="fas fa-spinner fa-pulse fa-2x "></i>
        </>
      ) : (
        <div className="seminar_Form">
          <Form>
            <FormGroup>
              <Label className="mt-3" for="Seminar Name">
                Seminar Name
              </Label>
              <Input
                value={title}
                name="title"
                onChange={e => setTitle(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="Seminar Name">
                Speaker Name
              </Label>
              <Input
                value={speaker}
                name="speaker"
                onChange={e => setSpeaker(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="Seminar Name">
                Speaker Short Info
              </Label>
              <Input
                value={speakerShortInfo}
                name="speakerShortInfo"
                onChange={e => setSpeakerShortInfo(e.target.value)}
              />
            </FormGroup>

            <div className="mt-2">
              <label htmlFor="">Upload Seminar Image</label>
              <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedCardFiles(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input required {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>
                        Drop files here or click to upload.
                      </h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              {cardImageError && (
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {cardImageError}
                </span>
              )}
              {cardImage && (
                <div className="mt-2">
                  <img
                    style={{
                      width: "200px",
                      height: "150px",

                    }}
                    src={
                      cardImage
                    }
                    alt="Card image"
                  />

                  <div className="d-flex justify-content-start mt-1"> <button onClick={() => setCardImage(null)} className="btn btn-danger">Clear</button></div>
                </div>
              )}
            </div>
            <FormGroup>
              <Label className="mt-3" for="Seminar Schedule">
                Seminar Schedule
              </Label>
              <Input
                value={schedule}
                id="exampleDate"
                placeholder="date placeholder"
                type="date"
                required
                onChange={e => handleDate(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="Seminar Time">
                Seminar Time
              </Label>
              <Input
                value={time}
                name="time"
                required
                onChange={e => {
                  const toBn = n => n.replace(/\d/g, d => "০১২৩৪৫৬৭৮৯"[d])
                  setTime(toBn(e.target.value))
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="Seminar Zone">
                Seminar Zone
              </Label>
              <Input
                value={zone}
                name="zone"
                type="select"
                required
                onChange={e => setZone(e.target.value)}
              >
                {seminarZone?.map(seminar => {
                  return (
                    <>
                      <option key={seminar?._id} value="" hidden>
                        Select
                      </option>
                      <option value={seminar?._id}>{seminar?.zone}</option>
                    </>
                  )
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="Seminar Type">
                Seminar Type
              </Label>
              <Input
                value={type}
                name="type"
                type="select"
                required
                onChange={e => setType(e.target.value)}
              >
                <option value="" hidden>
                  Select
                </option>
                <option value={"অনলাইন"}>অনলাইন</option>
                <option value={"অফলাইন"}>অফলাইন</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label className="mt-3" for="Seminar Link">
                Seminar Link
              </Label>
              <Input
                value={link}
                name="link"
                required
                onChange={e => setLink(e.target.value)}
              />
            </FormGroup>

            <Label for="Seminar Details">Seminar Details</Label>
            <Row>
              <Col
                lg={12}
                style={{
                  marginTop: "0px",
                  marginBottom: "10px",
                }}
              >
                <FBEditor
                  htmlData={details}
                  setHtmlData={setDetails}
                  prevData={
                    data !== null
                      ? data.description !== null
                        ? data.description
                        : ""
                      : ""
                  }
                />
              </Col>
            </Row>
          </Form>
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SeminarEdit
