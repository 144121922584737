import React, { useEffect, useState } from "react"
import renderHTML from "react-render-html"
import moment from "moment"
import { getInstructors } from "actions/instructor"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function item({ course, setStatus }) {
  const [instructors, setInstructors] = useState([])
  const [instructor, setInstructor] = useState(null)

  const history = useHistory()

  const handleDelete = courseId => {
    fetch(`${process.env.REACT_APP_URL}/api/course/delete/${courseId}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(() => {
        toastr.success("Course Deleted!!", "Course")
        setStatus(true)
      })
      .catch(err => console.log(err?.response?.data))
  }

  useEffect(() => {
    getInstructors()
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          res?.message?.length > 0 &&
            res?.message?.map(ins => {
              setInstructors(prevIns => [
                ...prevIns,
                { label: ins?.fullName, value: ins?._id },
              ])
            })
        }
      })
  }, [])

  useEffect(() => {
    const find =
      instructors?.length > 0
        ? instructors?.find(ins => ins?.value === course?.instructor)
        : undefined
    if (find !== undefined) {
      setInstructor(find)
    }
  }, [instructors, course])

  // console.log(instructor)

  return (
    <tr>
      <td>
        <img
          src={
            course?.upload_featured_image
              ? // eslint-disable-next-line no-undef
              `${process.env.REACT_APP_URL}/api/image/download/${course?.upload_featured_image}` 
              : ""
          }
          alt=""
          className="avatar-sm"
        />
      </td>
      <td>
        <h5 style={{ width: "300px" }} className="text-truncate font-size-14">
          <span style={{ fontSize: "20px" }} className="text-dark">
            {course?.title}
          </span>
        </h5>
      </td>
      <td> {moment(course?.updatedDate).format("lll")}</td>
      <td>{course?.enrollement_count} students</td>
      {/* <td>
        <span>{instructor?.label}</span>
      </td> */}
      <td>
        <button
          style={{ marginRight: "10px" }}
          onClick={() => history.push(`/courses/edit/${course?._id}`)}
          className="btn btn-primary w-md"
        >
          Edit
        </button>
        <button
          onClick={() =>
            window.confirm("Do you want to delete this course?")
              ? handleDelete(course?._id)
              : null
          }
          className="btn btn-danger w-md"
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

export default item
