import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import { Col, Container, Row, Table, Button } from "reactstrap"
import "../Courses/course.scss"


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"

const ManualEnrollPaymentIndex = () => {
  const [manualEnrollmentList, setManualEnrollmentList] = useState([])
  const [status, setStatus] = useState(false)

  const history = useHistory()

  // Get all courses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/new/api/manual-enrollment/get-all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        console.log({ data }, "get enroll data");
        setManualEnrollmentList(data.data)

      })
      .catch(err => console.log(err))
  }, [status])

  // delete specific course

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Manual Enrollment" breadcrumbItem="Manual Enrollment List" />
          <Button
            onClick={() => history.push(`/manual-enroll-payment/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Manual Enroll Payment
          </Button>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Course</th>
                        <th scope="col">TrxId</th>
                        <th scope="col">TrxNo</th>
                        <th scope="col">TrxMethod</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Trainer %</th>
                        <th scope="col">Trainer Amount</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Note</th>

                      </tr>
                    </thead>
                    <tbody>{
                      manualEnrollmentList?.map((list, i) => {
                        return <tr>
                          <td>{i + 1}</td>
                          <td>{list?.name}</td>
                          <td>{list?.email}</td>
                          <td>{list?.courseTitle}</td>
                          <td>{list?.transactionId}</td>
                          <td>{list?.transactionNumber}</td>
                          <td>{list?.transactionMethod}</td>
                          <td>{list?.amount}</td>
                          <td>{list?.trainerPercentage}</td>
                          <td>{list?.trainerAmount}</td>
                          <td>   <span style={{ maxWidth: "400px", whiteSpace: "pre-wrap" }}>
                            {list?.note}
                          </span></td>
                        </tr>
                      })
                    }
                      {/* {map(manualEnrollmentList, (seminar, index) => (
                        <SeminarList
                          setStatus={setStatus}
                          status={status}
                          seminar={seminar}
                          key={index}
                        />
                      ))} */}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ManualEnrollPaymentIndex)
