import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"

import {
  Col,
  Container,
  Row,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import "../Courses/course.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const TeacherRequest = () => {
  const [subscriberList, setSubscriberList] = useState([])
  const [status, setStatus] = useState(false)
  const [action, setAction] = useState(false)

  const [sType, setSType] = useState(1)

  const [pendingScholarships, setPendingScholarships] = useState([])
  const [approvedScholarships, setApprovedScholarships] = useState([])
  const [rejectedScholarships, setRejectedScholarships] = useState([])

  const [showScholarships, setShowScholarships] = useState(null)
  const [note, setNote] = useState({})

  console.log({ sType })
  useEffect(() => {
    if (subscriberList?.length > 0) {
      console.log("under length greter than 0")
      if (parseInt(sType) === 1) {
        console.log("P")
        setShowScholarships(pendingScholarships)
      } else if (parseInt(sType) === 2) {
        console.log("A")
        setShowScholarships(approvedScholarships)
      } else if (parseInt(sType) === 3) {
        console.log("R")
        setShowScholarships(rejectedScholarships)
      }
    } else {
      setShowScholarships([])
    }
  }, [sType, subscriberList])

  const history = useHistory()

  // Get all courses
  useEffect(() => {
    fetch(`https://www.ourprofessors.com/new/api/instructor/applications/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        console.log({ data }, "request")

        let approvedS = []
        let pendingS = []
        let rejectedS = []

        data?.data?.map(d => {
          console.log("inside map")
          if (d?.status == "ACCEPTED") {
            approvedS.push(d)
          } else if (d?.status == "REJECTED") {
            rejectedS.push(d)
          } else {
            pendingS.push(d)
          }
        })

        setApprovedScholarships(approvedS)
        setPendingScholarships(pendingS)
        setRejectedScholarships(rejectedS)
        setShowScholarships(pendingS)
        setSubscriberList(data.data)
      })
      .catch(err => console.log(err))
  }, [status, action])

  // Function to dynamically add or replace items
  const addOrUpdateNote = (id, text) => {
    const notes = note
    notes[id] = text
    setNote(notes)
  }

  console.log({ note })

  const handleApproved = (i, instructorAppId) => {
    console.log(i, note, note[i])

    const body = {
      status: "ACCEPTED",
      adminNote: note[i],
    }

    fetch(
      `https://www.ourprofessors.com/new/api/instructor/application/update/${instructorAppId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => res?.json())
      .then(data => {
        console.log(data, "approve")
        if (data?.success) {
          toastr.success(data?.message, "Instructor")
          delete note[i]
          setAction(!action)
        } else {
          toastr.error(data?.message, "Instructor")
        }
      })
      .catch(err => toastr.error(err?.response?.data?.message, "Instructor"))
  }

  const handleRejected = (i, instructorAppId) => {
    console.log(i, note, note[i])

    const body = {
      status: "REJECTED",
      adminNote: note[i],
    }

    fetch(
      `https://www.ourprofessors.com/new/api/instructor/application/update/${instructorAppId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => res?.json())
      .then(data => {
        console.log(data, "approve")
        if (data?.success) {
          toastr.success(data?.message, "Instructor")
          delete note[i]
          setAction(!action)
        } else {
          toastr.error(data?.message, "Instructor")
        }
      })
      .catch(err => toastr.error(err?.response?.data?.message, "Instructor"))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Instructor Request"
            breadcrumbItem="Instructor Request List"
          />
          {/* <Button
            onClick={() => history.push(`/subscriber/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Subscriber
          </Button> */}
          <Row>
            <FormGroup>
              <Label className="mt-3" for="Instructor Request">
                Instructor Request Status
              </Label>
              <Input
                name="zone"
                type="select"
                // onChange={e => setZone(e.target.value)}
                onChange={e => {
                  setSType(e.target.value)
                }}
              >
                <option value={1}>Pending</option>
                <option value={2}>Approved</option>
                <option value={3}>Rejected</option>
              </Input>
            </FormGroup>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone No</th>
                        <th scope="col">Message</th>
                        <th scope="col">Status</th>
                        <th scope="col">Admin Note</th>
                        {/* <th scope="col">Time</th>
                        <th scope="col">Zone</th>
                        <th scope="col">Type</th> */}
                        {/* <th scope="col">Option</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {map(subscriberList, (subscriber, index) => (
                                                <ScholarShipStudentList

                                                    data={subscriber}
                                                    key={index}
                                                />
                                            ))} */}

                      {showScholarships?.map((subscriber, index) => (
                        <tr key={index}>
                          <td>
                            <span className="text-dark">
                              {subscriber?.fullName}
                            </span>
                          </td>
                          <td>
                            <span>{subscriber?.email}</span>
                          </td>
                          <td>
                            <span>{subscriber?.phoneNumber}</span>
                          </td>
                          <td>
                            <span>{subscriber?.message}</span>
                          </td>
                          <td>
                            <span>{subscriber?.status}</span>
                          </td>
                          <td>
                            {subscriber.status === "PENDING" ? (
                              <Input
                                type="textarea"
                                id="textarea"
                                maxLength="620"
                                rows="3"
                                name="speaker"
                                value={note[index]}
                                onChange={e => {
                                  addOrUpdateNote(index, e.target.value)
                                }}
                              />
                            ) : (
                              subscriber.adminNote
                            )}
                          </td>

                          <td>
                            <span
                              style={{
                                width: "400px",
                                maxWidth: "400px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {subscriber?.reason}
                            </span>
                          </td>
                          {subscriber.status == "PENDING" ? (
                            <td>
                              {" "}
                              <button
                                onClick={() =>
                                  handleApproved(index, subscriber._id)
                                }
                                style={{ marginRight: "10px" }}
                                className="btn btn-primary w-md"
                              >
                                ACCEPT
                              </button>
                              <button
                                onClick={() =>
                                  handleRejected(index, subscriber._id)
                                }
                                style={{ marginRight: "10px" }}
                                className="btn btn-danger w-md"
                              >
                                REJECT
                              </button>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(TeacherRequest)
