import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useParams } from "react-router-dom"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { getStudentById } from "../../actions/students"
import CourseCard from "./CourseCard"
import "./GetStudentById.css"

const GetStudentById = props => {
  const [studentData, setStudentData] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    getStudentById(id)
      .then(r => r.json())
      .then(res => {
        if (res.success) {
          setStudentData(res?.message)
        }
      })
      .catch(err => console.log(err))
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h2>{studentData?.fullName}</h2>
                      <tr>
                        <td>Email</td>
                        <td className="pl-20">{studentData?.email}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td className="pl-20">{studentData?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>Username</td>
                        <td className="pl-20">@{studentData?.userName}</td>
                      </tr>
                      <tr>
                        <td>Blocked</td>
                        <td className="pl-20">
                          {studentData?.blocked === false ? "False" : "True"}
                        </td>
                      </tr>
                      <tr>
                        <td>Enrolled Course </td>
                        <td className="pl-20">
                          {studentData?.enrolled_courses?.length}
                        </td>
                      </tr>

                      {studentData?.Upload_academic_certificate && (
                        <div style={{ paddingTop: "20px" }}>
                          <a
                            style={{
                              background: "#38aba1",
                              display: "inline-block",
                              color: "white",
                              padding: "10px 20px",
                              borderRadius: "6px",
                            }}
                            href={`${process.env.REACT_APP_URL}/api/image/download/${studentData?.Upload_academic_certificate}`}
                            download
                          >
                            Download Certificate
                          </a>
                        </div>
                      )}

                      <div style={{ padding: "20px 0px" }}>
                        <h5>Enrolled Courses</h5>

                        <div>
                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                            >
                              <Table
                                id="tech-companies-1"
                                className="table table-striped table-bordered"
                              >
                                <Thead>
                                  <Tr>
                                    <Th>Title</Th>
                                    <Th>Bundle Course</Th>
                                    <Th data-priority="3">Status</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {studentData?.enrolled_courses?.length > 0 &&
                                    studentData?.enrolled_courses?.map(
                                      (course, i) => (
                                        <CourseCard
                                          userId={id}
                                          key={i}
                                          course={course}
                                        />
                                      )
                                    )}
                                </Tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GetStudentById
