import React, { useState, useEffect } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useHistory } from "react-router-dom"

function List({ book, handleDelete }) {
  const [downloadUrl, setDownloadUrl] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (book) {
      const downloadF = async () => {
        await fetch(
          `${process.env.REACT_APP_URL}/api/largeFile/download/generatePresignedURL/${book?.book_file}`,
          {
            method: "GET",
          }
        )
          .then(res => res.json())
          .then(data => {
            setDownloadUrl(data?.uploadURL)
          })
          .catch(err => console.log(err))
      }
      downloadF()
    }
  }, [book])

  return (
    <Tr>
      <Td>{book?.book_name}</Td>
      <Td>{book?.book_short_details}</Td>
      <Td>{book?.author_name}</Td>
      <Td>
        <img
          style={{
            width: "100px",
            height: "60px",
          }}
          src={
            book?.book_cover
              ? `${process.env.REACT_APP_URL}/api/book/download/${book?.book_cover}`
              : ""
          }
          alt=""
        />
      </Td>
      <Td>
        <a
          href={downloadUrl}
          //   onClick={() => handleDownload(book?.book_file)}
          target="_blank"
          download
        >
          Download
        </a>
      </Td>
      <Td>
        <button
          style={{ marginRight: "10px" }}
          onClick={() => history.push(`/book/edit/${book?._id}`)}
          className="btn btn-primary w-md"
        >
          Edit
        </button>
        <button
          onClick={() =>
            window.confirm("Do you want to delete this book?")
              ? handleDelete(book?._id)
              : null
          }
          className="btn btn-danger w-md"
        >
          Delete
        </button>
      </Td>
    </Tr>
  )
}

export default List
