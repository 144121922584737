import React, { useEffect, useState } from "react"
import renderHTML from "react-render-html"
import moment from "moment"
import { getInstructors } from "actions/instructor"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { MetaTags } from "react-meta-tags"
import { Container, FormGroup, Input, Label } from "reactstrap"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import Breadcrumbs from "components/Common/Breadcrumb"

function ScholarShipDetails() {
    const { id } = useParams()

    const history = useHistory();

    const [scholarshipDetails, setScholarshipDetails] = useState(null);
    const [note, setNote] = useState("")
    const [approvedPercentage, setApprovedPercentage] = useState(null);


    useEffect(() => {
        fetch(
            `https://www.ourprofessors.com/new/api/scholarship/get/${id}`,
            {
                method: "GET",
            }
        )
            .then(res => res?.json())
            .then(data => {
                console.log(data)
                if (data?.success) {
                    if ('status' in data?.data) {
                        setScholarshipDetails(data?.data)
                    }
                    else {
                        setScholarshipDetails({ ...data?.data, status: "PROCESSING" })
                    }

                } else {
                    //   message.error(data?.message, 3, "Support Ticket");
                }
            })
            .catch(err => {
                console.log(err);
                // message.error(err?.response?.data?.message, "Review");
            })
    }, [])



    const handleAccept = () => {
        console.log("clicked")
        if (isNaN(approvedPercentage)) {
            toastr.error("Approved percentage is not a number", "ScholarShip")
            // console.log("not a number");
            return
        }
        if ((parseInt(approvedPercentage) > 100 || parseInt(approvedPercentage) < 0)) {
            toastr.error("Approved percentage can't be less than 0 and greater than 100", "ScholarShip")
            return
        }

        if (approvedPercentage === null || approvedPercentage === "") {
            toastr.error("Approved percentage can't be empty", "Scholarship")
            return
        }

        const data = {

            status: "ACCEPTED",
            approvedPercentage: approvedPercentage,
            note: note

        }

        console.log("proceed");
        fetch(`https://www.ourprofessors.com/new/api/scholarship/update/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(res => res?.json())
            .then(res => {
                if (res.success) {
                    history.push(`/scholarship-student`)
                    toastr.success(res.message, "ScholarShip")

                }
            })
            .catch(err => {
                console.log(err?.response?.data)

            })
    }

    const handleReject = () => {
        console.log("clicked")
        const data = {
            status: "REJECTED",
            note: note
        }

        fetch(`https://www.ourprofessors.com/new/api/scholarship/update/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(res => res?.json())
            .then(res => {
                if (res.success) {
                    history.push(`/scholarship-student`)
                    toastr.success(res.message, "ScholarShip")

                }
            })
            .catch(err => {
                console.log(err?.response?.data)

            })
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Our Professor</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title="Scholarship"
                        breadcrumbItem="Scholarship student details"
                    />

                    {scholarshipDetails?.status === "ACCEPTED" ? <button style={{ marginRight: "10px", }}

                        className="btn btn-primary w-md">ACCEPTED</button>
                        : scholarshipDetails?.status === "REJECTED" ? <button style={{ marginRight: "10px" }}

                            className="btn btn-danger w-md">REJECTED</button> : null}
                    <div style={{ display: 'flex', alignItems: "center", marginTop: "10px" }}>
                        <p style={{ minWidth: "100px" }}>Name</p>   <h3>{scholarshipDetails?.name}</h3>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", marginTop: "15px" }}>
                        <p style={{ minWidth: "100px" }}>Email</p>   <h3>{scholarshipDetails?.email}</h3>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", marginTop: "15px" }}>
                        <p style={{ minWidth: "100px" }}>Phone</p>   <h3>{scholarshipDetails?.phoneNumber}</h3>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", marginTop: "15px" }}>
                        <p style={{ minWidth: "100px" }}>Condition</p>   <h3>{scholarshipDetails?.presentCondition}</h3>
                    </div>

                    <div style={{ display: 'flex', alignItems: "center", marginTop: "15px" }}>
                        <p style={{ minWidth: "100px" }}>Reason</p>   <h3>{scholarshipDetails?.reason}</h3>
                    </div>

                    {scholarshipDetails?.status === "REJECTED" ? null : <div style={{ display: 'flex', alignItems: "center", marginTop: "15px" }}>
                        <p style={{ minWidth: "100px" }}>Approved %</p>    <FormGroup>
                            {/* <Label className="mt-3" for="Seminar Name">
                                Approved %
                            </Label> */}
                            {scholarshipDetails?.status === "PROCESSING" ? <Input name="title" onChange={e => setApprovedPercentage(e.target.value)} /> : scholarshipDetails?.status === "ACCEPTED" ? <h3>{scholarshipDetails?.approvedPercentage}</h3> : null}
                        </FormGroup>
                    </div>}

                    <div style={{ display: 'flex', alignItems: "center", marginTop: "15px" }}>
                        <p style={{ minWidth: "100px" }}>Note</p>    <FormGroup>
                            {/* <Label className="mt-3" for="Seminar Name">
                                Note
                            </Label> */}
                            <Input type="textarea"
                                id="textarea"
                                disabled={!scholarshipDetails?.status === "PROCESSING"}
                                maxLength="620"
                                value={scholarshipDetails?.note}
                                rows="3" name="speaker" onChange={(e) => { setNote(e.target.value) }} />
                        </FormGroup>
                    </div>

                    {scholarshipDetails?.status === "PROCESSING" && <div className="mt-3">
                        <button style={{ marginRight: "10px" }}
                            onClick={() => { handleAccept() }}
                            className="btn btn-primary w-md">Accept</button>

                        <button onClick={() =>
                            window.confirm(
                                "Do you want to reject this scholarship?"
                            )
                                ? handleReject()
                                : null
                        }
                            className="btn btn-danger w-md">Reject</button>
                    </div>}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ScholarShipDetails
