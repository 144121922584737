import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  CardSubtitle,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
// React router dom
import { useHistory } from "react-router"
import FBEditor from "components/Editor/Editor"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { getInfo, updateInfo } from "actions/information"

const Category = props => {
  const [information, setInformation] = useState(null)

  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [visitors, setVisitors] = useState(0)

  useEffect(() => {
    getInfo()
      .then(r => r.json())
      .then(res => {
        setInformation(res?.data)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (information) {
      setEmail(information?.information?.email)
      setPhone(information?.information?.phone)
      setVisitors(information?.information?.visitors)
    }
  }, [information])

  const handleUpdate = () => {
    const data = {
      email,
      phone,
      visitors,
    }
    updateInfo(data)
      .then(r => r.json())
      .then(res => {
        if (res) {
          toastr.success("Data saved successful!")
        }
      })
      .catch(err => {
        if (err) {
          toastr.error("Something went wrong")
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dynamic Data")}
            breadcrumbItem={props.t("Dynamic Data")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>Dynamic Data</CardTitle>

                      <div>
                        <label htmlFor="">Contact us emails</label>
                        <FBEditor
                          htmlData={email}
                          setHtmlData={setEmail}
                          prevData={
                            information !== null
                              ? information?.information?.email !== null
                                ? information?.information?.email
                                : ""
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="">Footer Phone Numbers</label>
                        <FBEditor
                          htmlData={phone}
                          setHtmlData={setPhone}
                          prevData={
                            information !== null
                              ? information?.information?.phone !== null
                                ? information?.information?.phone
                                : ""
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="">Total Visitor</label>
                        <input
                          placeholder="Enter total visitor"
                          className="form-control"
                          type="number"
                          name=""
                          id=""
                          value={visitors}
                          onChange={e => setVisitors(e.target.value)}
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          onClick={handleUpdate}
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Category)
