import React from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import "./Support.css"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { UploadOutlined } from "@ant-design/icons"
import { publicImageUpload } from "actions/imageUpload"

const SupportDetails = () => {
  const { supportTicketId } = useParams()
  const [supportDetails, setSupportDetails] = useState([])
  const [userMessage, setUserMessage] = useState("")
  const [certificate, setCertificate] = useState(null)
  const [status, setStatus] = useState(false)
  const [error, setError] = useState(null)
  const [supportImage, setSupportImage] = useState(null)
  const [change, setChange] = useState(true)

  // const history = useHistory();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/api/support/details/${supportTicketId}`,
      {
        method: "GET",
      }
    )
      .then(res => res?.json())
      .then(data => {
        console.log(data)
        if (data?.success) {
          setSupportDetails(data?.data)
        } else {
          //   message.error(data?.message, 3, "Support Ticket");
        }
      })
      .catch(err => {
        // message.error(err?.response?.data?.message, "Review");
      })
  }, [change])

  const handleReply = () => {
    console.log("clicked")
    const data = {
      reply: [
        {
          message: userMessage,
          givenBy: "ADMIN",
          image: certificate,
        },
      ],
    }

    fetch(`${process.env.REACT_APP_URL}/api/support/reply/${supportTicketId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(res => res?.json())
      .then(res => {
        if (res.success) {
          setChange(!change)
          toastr.success(res.message, "Support ticket")
          setCertificate(undefined)
        }
      })
      .catch(err => {
        console.log(err?.response?.data)
        setCertificate(undefined)
      })
  }

  const handleCertificateUpload = e => {
    setError(null)
    const extension = e.target.files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG" ||
      extension === "pdf" ||
      extension === "PDF"
    ) {
      setStatus(true)
      publicImageUpload(e)
        .then(res => res.json())
        .then(data => {
          console.log(data)
          setSupportImage(data?.Location)
          if (data?.success === undefined) {
            setCertificate(data?.Location)
            setStatus(false)
          } else {
            if (!data?.success) {
              setStatus(false)
              toastr.error(data.message, "Support ticket")
            } else {
              // setCertificate(data?.key);
              setCertificate(data?.Location)
              setStatus(false)
            }
          }
        })
        .catch(err => {
          console.log(err)
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data)
          } else {
            toastr.error(err?.response?.data?.message)
          }
        })
    } else {
      setError("Unknown type! try to select png, jpg or pdf type!")
    }
  }

  const fileExtension =
    certificate !== null &&
    certificate?.substr(certificate?.lastIndexOf(".") + 1)
  const certificateViewer = () => {
    if (fileExtension !== undefined) {
      if (
        fileExtension === "png" ||
        fileExtension === "PNG" ||
        fileExtension === "jpg" ||
        fileExtension === "JPG" ||
        fileExtension === "jpeg" ||
        fileExtension === "JPEG"
      ) {
        return (
          <img
            // width={200}
            style={{
              width: "100px",
              height: "90px",
            }}
            src={certificate}
            // src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
            alt="SupportImage"
          />
        )
      } else {
        return (
          <>
            <a
              style={{
                background: "#38aba1",
                display: "inline-block",
                color: "white",
                padding: "10px 20px",
                borderRadius: "6px",
              }}
              href={`${process.env.REACT_APP_URL}/api/image/download/${certificate}`}
              download
            >
              Download Your Support Image
            </a>
          </>
        )
      }
    }
  }

  return (
    <div className="supportTicketReplyDiv">
      <div className="supportDetailsDiv">
        <div
          style={{
            borderBottom: "1px solid black",
          }}
          className="w-100 d-flex justify-content-between chattingDivheader"
        >
          <div className="conversationTop">
            <h4 className="heading" id="heading1">
              Support Ticket Details
            </h4>
            <h4 className="heading" id="heading2">
              Subject: {supportDetails?.subject}
            </h4>
          </div>
          <div className="conversationTop">
            <h4 className="heading">{supportDetails?.user?.email}</h4>
            <h4 className="heading">{supportDetails?.user?.phoneNumber}</h4>
          </div>
        </div>
        <h5 className="text-start">Conversation :</h5>

        <div className="chattingDiv">
          <div className="chattingText">
            <div className="chatMessageLeft">
              <div className="singleMessageLeft">
                <h5
                  style={{
                    color: "white",
                  }}
                  className=""
                >
                  {supportDetails?.message}
                </h5>
              </div>
            </div>
            <p
              style={{
                fontSize: "12px",
              }}
              className="text-start"
            >
              Send By User
            </p>

            {supportDetails?.image ? (
              <>
                <div className="chatMessageLeft">
                  <div className="chatImage">
                    <a href={supportDetails?.image} target="new">
                      <img
                        className="img-fluid"
                        src={supportDetails?.image}
                        alt="supportImage"
                        onClick={() => {
                          // window.open({supportDetails?.image})
                        }}
                      />
                    </a>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "12px",
                  }}
                  className="text-start"
                >
                  Send By User
                </p>
              </>
            ) : null}

            {supportDetails?.reply?.map((r, i) => {
              return (
                <div key={i}>
                  {" "}
                  {r?.givenBy === "USER" ? (
                    <>
                      {r?.image ? (
                        <>
                          <div className="chatMessageLeft">
                            <div className="chatImage">
                              <a href={r?.image} target="new">
                                <img
                                  className="img-fluid"
                                  src={r?.image}
                                  alt="supportImage"
                                  onClick={() => {
                                    // window.open({supportDetails?.image})
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                            className="text-start"
                          >
                            Send By User
                          </p>
                        </>
                      ) : null}
                      {r?.message ? (
                        <>
                          <div className="chatMessageLeft">
                            <div className="singleMessageLeft">
                              <h5
                                style={{
                                  color: "white",
                                }}
                                className="heading"
                              >
                                {r?.message}
                              </h5>
                            </div>
                          </div>
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                            className="text-start"
                          >
                            Send By User
                          </p>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {r?.image ? (
                        <>
                          <div className="chatMessageRight">
                            <div className="chatImage">
                              <a href={r?.image} target="new">
                                <img
                                  className="img-fluid"
                                  src={r?.image}
                                  alt="supportImage"
                                  onClick={() => {
                                    // window.open({supportDetails?.image})
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                            className="text-end"
                          >
                            Send By Admin
                          </p>
                        </>
                      ) : null}
                      {r?.message ? (
                        <>
                          <div className="chatMessageRight">
                            <div className="singleMessageRight">
                              <h5
                                style={{
                                  color: "white",
                                }}
                                className="heading"
                              >
                                {r?.message}
                              </h5>
                            </div>
                          </div>
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                            className="text-end pb-1"
                          >
                            Send By Admin
                          </p>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
        {supportDetails?.solved ? null : (
          <div className="replySendDiv">
            <textarea
              name="Message"
              onChange={e => {
                setUserMessage(e.target.value)
              }}
              rows="3"
              cols="70"
              className="input2"
              type="textarea"
              placeholder="Your Valuable Question"
            ></textarea>

            <div>
              <label htmlFor="certificate">
                <p className="replyImageUp">
                  <span className="ms-2">
                    <UploadOutlined />
                  </span>
                </p>
              </label>

              <input
                onChange={e => handleCertificateUpload(e)}
                style={{ display: "none" }}
                id="certificate"
                type="file"
                accept=".png, .jpg, .jpeg, .pdf, .zip"
              />

              {/* {error && <span className="error">{error}</span>} */}

              {certificate !== undefined ? (
                certificate !== null ? (
                  <div
                    className="replyImageUpShow"
                    style={{ marginTop: "5px" }}
                  >
                    {certificate !== undefined
                      ? certificate !== null
                        ? certificateViewer()
                        : null
                      : null}
                  </div>
                ) : null
              ) : null}

              <button onClick={handleReply} className="replySend">
                Send
              </button>
            </div>
            {/* <button onClick={handleReply} className="replySend">
            Send
          </button> */}
          </div>
        )}
      </div>
    </div>
  )
}

export default SupportDetails
