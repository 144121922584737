import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  FormGroup,
} from "reactstrap"

// Form Editor

import { useParams } from "react-router-dom"
//Import Breadcrumb

//i18n
import { imageUpload } from "actions/imageUpload"
import { getInstructors } from "actions/instructor"
import CourseCurriculum from "components/CourseComponent/Curriculum"
import FBEditor from "components/Editor/Editor"
import { withTranslation } from "react-i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const AddNewCourse = props => {
  const [getCourseDetails, setGetCourseDetails] = useState(null)
  const [addStatus, setAddStatus] = useState(false)

  /**
   * * State for course data
   */
  const [title, setTitle] = useState("")
  const [slug, setSlug] = useState("")
  const [fakeStudentEnrolled, setFakeStudentEnrolled] = useState(0)
  const [regularPrice, setRegularPrice] = useState(0)
  const [salePrice, setSalePrice] = useState(0)
  const [instructor, setInstructor] = useState("")
  const [thumbnailImage, setThumbnailImage] = useState([])
  const [details, setDetails] = useState(null)
  const [allCategories, setAllCategories] = useState([])
  const [categories, setCategories] = useState([])
  const [categoryOption, setCategoryOption] = useState([])
  const [instructors, setInstructors] = useState([])
  const [finalCategory, setFinalCategory] = useState([])
  const [image, setImage] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [courseDuration, setCourseDuration] = useState("")
  const [shortDescription, setShortDescription] = useState(null)
  const [paidCourse, setPaidCourse] = useState(true)
  const [liveClass, setLiveClass] = useState(true)
  const [textareabadge, settextareabadge] = useState(0)
  const [textcount, settextcount] = useState(0)
  const [profileImageError, setProfileImageError] = useState(null)
  const [publish, setPublish] = useState(true)
  const [benifits, setBenifits] = useState([{ benifit: "" }])
  const [courseDataGet, setCourseDataGet] = useState(false)
  const [courseValidDays, setCourseValidDays] = useState("")

  const [cardImage, setCardImage] = useState(null)
  const [pageImage, setPageImage] = useState(null)
  const [cardImageError, setCardImageError] = useState(null)
  const [courseNotice, setCourseNotice] = useState("")
  const [visibleNotice, setVisibleNotice] = useState(false)

  const [noticeDateVisible, setNoticeDateVisible] = useState(false)
  const [noticeEndDate, setNoticeEndDate] = useState("")
  const [enrollOff, setEnrollOff] = useState(false)
  const [trainerPercentage, setTrainerPercentage] = useState(0);

  const { id } = useParams()

  console.log({ categories, finalCategory });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get/${id}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => {
        setGetCourseDetails(data?.message)
        setCourseDataGet(true)
      })
      .catch(err => console.log(err))
    setAddStatus(false)
  }, [id, addStatus])

  useEffect(() => {
    if (getCourseDetails !== null && getCourseDetails?.benefits.length > 0) {
      let b = []
      getCourseDetails?.benefits?.map((pd, index) => {
        let preBenifit = {}
        preBenifit["benifit"] = pd?.benifit

        // questions[index].push(preQuestion)
        b.splice(index, 0, preBenifit)
      })

      setBenifits(b)
    }
  }, [courseDataGet])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/category/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => setAllCategories(data?.message))
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    allCategories?.length > 0 &&
      allCategories?.map(category =>
        setCategoryOption(prevCategory => [
          ...prevCategory,
          { label: category?.category_name, value: category?._id },
        ])
      )
  }, [allCategories])

  useEffect(() => {
    categories?.length > 0 &&
      categories?.map(category => {
        setFinalCategory(prevCategory => [...prevCategory, category?.value])
      })
  }, [categories])

  const categoryNames = [
    {
      options: categoryOption,
    },
  ]

  function convertISODateToCustomFormat(isoDate) {
    if (isoDate) {
      const date = new Date(isoDate)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const day = String(date.getDate()).padStart(2, "0")

      return `${year}-${month}-${day}`
    } else return null
  }

  useEffect(() => {
    setTitle(getCourseDetails !== null ? getCourseDetails?.title : "")
    setCourseDuration(
      getCourseDetails !== null ? getCourseDetails?.course_duration : ""
    )
    setFakeStudentEnrolled(
      getCourseDetails !== null ? getCourseDetails?.enrollement_count : ""
    )
    setRegularPrice(
      getCourseDetails !== null ? getCourseDetails?.regular_price : ""
    )
    setSalePrice(getCourseDetails !== null ? getCourseDetails?.sale_price : "")
    setDetails(
      getCourseDetails !== null
        ? getCourseDetails?.course_description !== null
          ? getCourseDetails?.course_description
          : ""
        : ""
    )
    setImage(
      getCourseDetails !== null && getCourseDetails?.upload_featured_image
    )

    setShortDescription(
      getCourseDetails !== null && getCourseDetails?.course_short_description
    )

    setLiveClass(getCourseDetails !== null && getCourseDetails?.course_live)

    setPaidCourse(getCourseDetails !== null && getCourseDetails?.course_paid)
    setPublish(getCourseDetails !== null && getCourseDetails?.course_publish)
    setSlug(getCourseDetails !== null && getCourseDetails?.slug)
    setCourseValidDays(
      getCourseDetails !== null ? getCourseDetails?.validDays : 0
    )

    setCardImage(getCourseDetails !== null && getCourseDetails?.cardImage)
    setPageImage(getCourseDetails !== null && getCourseDetails?.pageImage)
    setCourseNotice(
      getCourseDetails !== null
        ? getCourseDetails?.courseNotice !== null
          ? getCourseDetails?.courseNotice
          : ""
        : ""
    )
    setVisibleNotice(
      getCourseDetails !== null && getCourseDetails?.visibleNotice
    )

    setNoticeDateVisible(
      getCourseDetails !== null && getCourseDetails?.noticeDateVisible
    )

    setFinalCategory(getCourseDetails !== null && getCourseDetails?.category)

    setTrainerPercentage(getCourseDetails !== null && getCourseDetails?.trainerPercentage)

    const isoDate =
      getCourseDetails !== null &&
      getCourseDetails?.noticeEndDate !== undefined &&
      getCourseDetails?.noticeEndDate
    const customFormatDate = convertISODateToCustomFormat(isoDate)

    console.log("custom formDate", customFormatDate)
    customFormatDate ? setNoticeEndDate(customFormatDate) : setNoticeEndDate("")
    setEnrollOff(getCourseDetails !== null && getCourseDetails?.enrollOff)
  }, [getCourseDetails])

  // console.log("Course details notice end date", noticeEndDate)

  useEffect(() => {
    const find =
      instructors?.length > 0
        ? instructors?.find(ins => ins?.value === getCourseDetails?.instructor)
        : undefined
    if (find !== undefined) {
      setInstructor(find)
    }
  }, [instructors, getCourseDetails])

  useEffect(() => {
    if (allCategories && getCourseDetails !== null) {
      getCourseDetails?.category?.map(ct => {
        const findCategory = allCategories?.find(c => c?._id === ct)

        if (findCategory !== undefined) {
          setCategories(prevCategory => [
            ...prevCategory,
            { label: findCategory?.category_name, value: findCategory?._id },
          ])
        }
      })
    }
  }, [allCategories, getCourseDetails])

  useEffect(() => {
    getInstructors()
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          res?.message?.length > 0 &&
            res?.message?.map(ins => {
              setInstructors(prevIns => [
                ...prevIns,
                { label: ins?.fullName, value: ins?._id },
              ])
            })
        }
      })
  }, [])

  const optionGroup = [
    {
      options: instructors,
    },
  ]

  function handleAcceptedFiles(files) {
    setProfileImageError(null)
    const extension = files[0]?.name?.split(".").pop()
    console.log(extension)

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setImage(data?.key)
            setPageImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setImage(data?.key)
              setPageImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setProfileImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  function handleAcceptedCardFiles(files) {
    setCardImageError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setCardImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setCardImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setCardImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleUpdate = async e => {
    e.preventDefault()
    if (e.keyCode === 13) {
      e.preventDefault()
    }
    let finalBenifits = []
    benifits.map(benifit => {
      if (benifit.benifit !== "") {
        finalBenifits.push(benifit)
      }
    })

    if (noticeDateVisible && noticeEndDate === "") {
      toastr.error("Please enter notice end date", "Course")
      return
    }

    const body = {
      title,
      slug,
      course_description: details,
      catagory: "Programming",
      instructorId: instructor?.value,
      regular_price: Number(regularPrice),
      sale_price: Number(salePrice),
      enrollement_count: Number(fakeStudentEnrolled),
      tag: ["C", "programming"],
      category: [...new Set(finalCategory)],
      upload_featured_image: image,
      course_duration: courseDuration,
      course_paid: paidCourse,
      course_live: liveClass,
      course_short_description: shortDescription,
      course_publish: publish,
      benefits: [...finalBenifits],
      validDays: courseValidDays,
      cardImage: cardImage,
      pageImage: pageImage,
      courseNotice: courseNotice,
      visibleNotice: visibleNotice,
      noticeEndDate: noticeEndDate !== "" ? noticeEndDate : null,
      noticeDateVisible: noticeDateVisible,
      enrollOff: enrollOff,
      trainerPercentage: trainerPercentage
    }
    console.log("edit course", body)
    await fetch(`${process.env.REACT_APP_URL}/api/course/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Course")
        } else {
          toastr.error(data?.message, "Course")
        }

        console.log("edit course result", data)
      })
      .catch(err => console.log(err))
  }
  const handleBodyChange = e => {
    setDetails(e)
  }

  function handleMulti(e) {
    setCategories(e)
    setFinalCategory([])
  }

  function textareachange(event) {
    setShortDescription(event.target.value)
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  // course benifits

  const handleInputChange = (e, index) => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
    const { name, value } = e.target
    const list = [...benifits]
    list[index][name] = value
    setBenifits(list)
  }

  const handleRemoveClick = index => {
    const list = [...benifits]
    list.splice(index, 1)
    setBenifits(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setBenifits([
      ...benifits,
      {
        benifit: "",
      },
    ])
  }

  // console.log("enrollOff", enrollOff)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Title
                            </Label>
                            <Input
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Course Title"
                            />
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Slug
                            </Label>
                            <Input
                              required
                              value={slug}
                              onChange={e => setSlug(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>

                          <Row>
                            <Col
                              lg={12}
                              style={{ marginTop: "0px", marginBottom: "10px" }}
                            >
                              <FBEditor
                                htmlData={details}
                                setHtmlData={setDetails}
                                prevData={
                                  getCourseDetails !== null
                                    ? getCourseDetails?.course_description !==
                                      null
                                      ? getCourseDetails?.course_description
                                      : ""
                                    : ""
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <div className="mt-2 mb-3">
                              <Label>Short Description</Label>

                              <Input
                                type="textarea"
                                id="textarea"
                                onChange={e => {
                                  textareachange(e)
                                }}
                                value={shortDescription}
                                maxLength="620"
                                rows="3"
                                placeholder="Short description has a limit of 620 chars."
                              />
                              {textareabadge ? (
                                <span className="badgecount badge bg-success">
                                  {" "}
                                  {textcount} / 620{" "}
                                </span>
                              ) : null}
                            </div>
                          </Row>

                          {benifits?.map((x, i) => {
                            return (
                              <div key={i} className="box ">
                                {/* <p className="me-3 mt-3 "></p> */}
                                <Form>
                                  <FormGroup>
                                    <Label
                                      className="mt-3 fw-bold"
                                      for="Question"
                                    >
                                      Benifit NO : {i + 1}
                                    </Label>
                                    <Input
                                      name="benifit"
                                      placeholder="Course Benifit"
                                      value={x.benifit}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </FormGroup>
                                </Form>
                                <br />
                                <div>
                                  {benifits.length !== 1 && (
                                    <button
                                      // className="deleteButton"
                                      // onClick={() => handleRemoveClick(i)}
                                      onClick={() =>
                                        window.confirm(
                                          "Do you want to delete this benifit?"
                                        )
                                          ? handleRemoveClick(i)
                                          : null
                                      }
                                      className="btn btn-danger w-md"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <span>Delete this benifit</span>
                                    </button>
                                  )}
                                  {benifits.length - 1 === i && (
                                    <button
                                      className="addButton ms-3"
                                      onClick={handleAddClick}
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <span>Add New benifit</span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            )
                          })}

                          <div className="mt-3">
                            <Row>
                              <CourseCurriculum
                                setAddStatus={setAddStatus}
                                addStatus={addStatus}
                                chapters={getCourseDetails?.chapter}
                                id={getCourseDetails?._id}
                              />
                            </Row>
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Fake Student Enrolled
                            </Label>
                            <Input
                              onWheel={e => e.target.blur()}
                              value={fakeStudentEnrolled}
                              onChange={e =>
                                setFakeStudentEnrolled(e.target.value)
                              }
                              type="number"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="0"
                            />
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="courseDuration">
                              Course Duration
                            </Label>
                            <Input
                              value={courseDuration}
                              onChange={e => setCourseDuration(e.target.value)}
                              type="text"
                              className="form-control"
                              id="courseDuration"
                              placeholder="Ex: 15 hr 30 mints"
                            />
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="courseDuration">
                              Course Valid Days
                            </Label>
                            <Input
                              value={courseValidDays}
                              onChange={e => setCourseValidDays(e.target.value)}
                              type="text"
                              className="form-control"
                              id="courseDuration"
                              placeholder="Days"
                            />
                          </div>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">
                                  Regular Price
                                </Label>
                                <Input
                                  onWheel={e => e.target.blur()}
                                  value={regularPrice}
                                  onChange={e =>
                                    setRegularPrice(e.target.value)
                                  }
                                  type="number"
                                  className="form-control"
                                  id="formrow-email-Input"
                                  placeholder="0"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-password-Input">
                                  Sale Price
                                </Label>
                                <Input
                                  onWheel={e => e.target.blur()}
                                  value={salePrice}
                                  onChange={e => setSalePrice(e.target.value)}
                                  type="number"
                                  className="form-control"
                                  id="formrow-password-Input"
                                  placeholder="0"
                                />
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-3">
                            <Label>Instructors</Label>
                            <Select
                              value={instructor}
                              onChange={data => {
                                setInstructor(data)
                              }}
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                            />
                          </div>

                          <div className="mb-3">
                              <Label htmlFor="formrow-trainer-percentage">Trainer Percentage</Label>
                              <Input
                                required
                                value={trainerPercentage}
                                onChange={e => setTrainerPercentage(e.target.value)}
                                type="text"
                                className="form-control"
                                id="formrow-trainer-percentage"
                              />
                            </div>

                          <div className="mb-3">
                            <label className="control-label">
                              Select Categories
                            </label>
                            <Select
                              value={categories}
                              isMulti={true}
                              onChange={e => {
                                handleMulti(e)
                              }}
                              options={categoryNames}
                              classNamePrefix="select2-selection"
                            />
                          </div>

                          <div>
                            <label htmlFor="">Upload Featured Image</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                          {profileImageError && (
                            <span
                              style={{
                                display: "block",
                                marginTop: "10px",
                                color: "red",
                              }}
                            >
                              {profileImageError}
                            </span>
                          )}

                          {getCourseDetails !== null && (
                            <div className="mt-3">
                              <img
                                style={{
                                  width: "200px",
                                  height: "150px",
                                }}
                                src={
                                  image
                                    ? `${process.env.REACT_APP_URL}/api/image/download/${image}`
                                    : ""
                                }
                                alt=""
                              />

                              <div className="d-flex justify-content-start mt-1">
                                {" "}
                                <button
                                  onClick={() => setImage(null)}
                                  className="btn btn-danger"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          )}

                          <div className="mt-2">
                            <label htmlFor="">Upload Card Image</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedCardFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input required {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            {cardImageError && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              >
                                {cardImageError}
                              </span>
                            )}
                            {cardImage && (
                              <div className="mt-2">
                                <img
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                  }}
                                  src={cardImage}
                                  alt="Card image"
                                />

                                <div className="d-flex justify-content-start mt-1">
                                  {" "}
                                  <button
                                    onClick={() => setCardImage(null)}
                                    className="btn btn-danger"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mt-2">
                            <label htmlFor="">Upload Card Image</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedCardFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input required {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            {cardImageError && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              >
                                {cardImageError}
                              </span>
                            )}
                            {cardImage && (
                              <div className="mt-2">
                                <img
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                  }}
                                  src={cardImage}
                                  alt="Card image"
                                />

                                <div className="d-flex justify-content-start mt-1">
                                  {" "}
                                  <button
                                    onClick={() => setCardImage(null)}
                                    className="btn btn-danger"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">
                              Course Notice Visible
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="visibleNotice"
                                id="free"
                                value="false"
                                checked={!visibleNotice ? true : false}
                                onChange={e => setVisibleNotice(false)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="free"
                              >
                                False
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="visibleNotice"
                                id="paid"
                                value="True"
                                checked={visibleNotice ? true : false}
                                onChange={e => setVisibleNotice(true)}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="paid"
                              >
                                True
                              </label>
                            </div>
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              Course Notice
                            </Label>
                            <Row>
                              <Col
                                lg={12}
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "10px",
                                }}
                              >
                                <FBEditor
                                  htmlData={courseNotice}
                                  setHtmlData={setCourseNotice}
                                  prevData={
                                    getCourseDetails !== null
                                      ? getCourseDetails?.courseNotice !== null
                                        ? getCourseDetails?.courseNotice
                                        : ""
                                      : ""
                                  }
                                />
                              </Col>
                            </Row>
                          </div>

                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">
                              Notice End Date Visible
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="noticeEndDateVisible"
                                id="free"
                                value="false"
                                checked={!noticeDateVisible ? true : false}
                                onChange={e => setNoticeDateVisible(false)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="free"
                              >
                                False
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="noticeEndDateVisible"
                                id="paid"
                                value="True"
                                checked={noticeDateVisible ? true : false}
                                onChange={e => setNoticeDateVisible(true)}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="paid"
                              >
                                True
                              </label>
                            </div>
                          </div>
                          <FormGroup>
                            <Label for="exampleDate">Notice Ending Date</Label>
                            <Input
                              id="exampleDate"
                              name="endingDate"
                              placeholder="Notice End Date"
                              type="date"
                              value={noticeEndDate}
                              onChange={e => setNoticeEndDate(e.target.value)}
                            />
                          </FormGroup>

                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">
                              Enrollment Off
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="newEnrollment"
                                id="free"
                                value="false"
                                checked={!enrollOff ? true : false}
                                onChange={e => setEnrollOff(false)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="free"
                              >
                                False
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="newEnrollment"
                                id="paid"
                                value="True"
                                checked={enrollOff ? true : false}
                                onChange={e => setEnrollOff(true)}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="paid"
                              >
                                True
                              </label>
                            </div>
                          </div>
                          <div>
                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Free/Paid Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="free-paid-status"
                                  id="free"
                                  value="Free"
                                  checked={paidCourse === true ? false : true}
                                  onChange={e => setPaidCourse(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="free"
                                >
                                  Free
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="free-paid-status"
                                  id="paid"
                                  value="Paid"
                                  checked={paidCourse === false ? false : true}
                                  onChange={e => setPaidCourse(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="paid"
                                >
                                  Paid
                                </label>
                              </div>
                            </div>
                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Class Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="class-status"
                                  id="live"
                                  value="Live"
                                  checked={liveClass === true ? true : false}
                                  onChange={e => setLiveClass(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="live"
                                >
                                  Live Class
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="class-status"
                                  id="prerecord"
                                  value="Prerecord"
                                  checked={liveClass === false ? true : false}
                                  onChange={e => setLiveClass(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="prerecord"
                                >
                                  Prerecord Class
                                </label>
                              </div>
                            </div>

                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Publish Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="draft"
                                  value="Draft"
                                  checked={!publish ? true : false}
                                  onChange={e => setPublish(false)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="draft"
                                >
                                  Draft
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="Publish"
                                  value="Publish"
                                  checked={publish ? true : false}
                                  onChange={e => setPublish(true)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  Publish
                                </label>
                              </div>
                            </div>

                            <button
                              disabled={uploadStatus ? true : false}
                              type="submit"
                              onClick={handleUpdate}
                              className="btn btn-primary w-md mt-3"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddNewCourse.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

AddNewCourse.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(AddNewCourse)
