import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StudentList from "../../components/StudentList"

//i18n
import { withTranslation } from "react-i18next"
import StudentFilterList from "components/StudentFilterList"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

//redux
const transactionMethods = [{ _id: 1, method: "BKASH" }, { _id: 2, method: "NAGAD" }, { _id: 3, method: "CASH" }, { _id: 4, method: "OTHERS" },]
const ManualEnrollPayment = props => {
    const [instructorEmail, setInstructorEmail] = useState(null)
    const [selectedStudents, setSelectedStudents] = useState([])
    const [selectCourses, setSelectCourses] = useState([])
    const [students, setStudents] = useState([])
    const [courseList, setCourseList] = useState([])
    const [bundleCourseList, setBundleCourseList] = useState([])
    const [finalCourseList, setFinalCourseList] = useState([])
    const [finalCourses, setFinalCourses] = useState([])

    const history = useHistory();

    // Get all courses
    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(res => res.json())
            .then(data => {
                data?.message?.map(course => {
                    setCourseList(prevData => [
                        ...prevData,
                        { label: course?.title, value: course?._id },
                    ])
                })
                // setCourseList(data?.message)
            })
            .catch(err => console.log(err?.response?.data))
    }, [])

    //** Bundle Course List */
    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/bundle/getall`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data?.message?.length > 0) {
                    data?.message?.map(course => {
                        setBundleCourseList(prevData => [
                            ...prevData,
                            { label: course?.name, value: course?._id },
                        ])
                    })
                }
            })
    }, [])

    // useEffect(() => {
    //   getAllStudents()
    //     .then(res => res.json())
    //     .then(data => {
    //       if (data?.success) {
    //         data?.message?.map(st => {
    //           setStudents(prevData => [
    //             ...prevData,
    //             { label: st?.email, value: st?.email },
    //           ])
    //         })
    //       }
    //     })
    // }, [])

    useEffect(() => {
        const oneArray = [courseList, bundleCourseList]
        setFinalCourseList(oneArray.flat())
    }, [courseList, bundleCourseList])

    useEffect(() => {
        const key = "value"
        const arrayUniqueByKey = [
            ...new Map(finalCourseList.map(item => [item[key], item])).values(),
        ]
        setFinalCourses(arrayUniqueByKey)
    }, [finalCourseList])

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    useEffect(() => {
        const key = "value"
        const arrayUniqueByKey = [
            ...new Map(finalCourseList.map(item => [item[key], item])).values(),
        ]
        setFinalCourses(arrayUniqueByKey)
    }, [finalCourseList])
    const studentOptions = [...new Set(students)]

    const handleSelectCourse = data => {
        setSelectCourses(data)
    }

    console.log(selectedStudents, selectCourses)

    const [transactionId, setTransactionId] = useState("");
    const [transactionMethod, setTransactionMethod] = useState("");
    const [transactionNumber, setTransactionNumber] = useState("");
    const [note, setNote] = useState("");
    const [amount, setAmount] = useState("");

    const getCurrentDate = () => {
        let currentDate = new Date();

        // Get the year, month, and day components from the current date
        let year = currentDate.getFullYear();
        let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to get month in range 1-12, padStart ensures two digits
        let day = currentDate.getDate().toString().padStart(2, '0'); // padStart ensures two digits

        // Concatenate them in the desired format
        let formattedDate = `${year}/${month}/${day}`;

        return formattedDate;
    }




    const handleSubmit = async e => {
        const studentsEmail = selectedStudents
        const coursesId = []

        // selectCourses?.map(course => coursesId.push(course?.value))

        const payload = {
            note,
            amount: parseInt(amount),
            userId: studentsEmail[0]?._id,
            courseId: selectCourses?.value,
            transactionId,
            transactionMethod,
            transactionNumber,
            date: getCurrentDate()
        }

        console.log({ payload })
        await fetch(`${process.env.REACT_APP_URL}/new/api/manual-enrollment/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(data => {

                console.log({ data }, "create");
                if (!data?.success) {
                    toastr.error(data?.message)


                } else if (data?.success) {
                    toastr.success(data?.message)
                    history.push('/manual-enroll-payment')
                }


                console.log(data?.message)
            })
            .catch(err => console.log(err))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Qlearn </title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title="Manual Enrollment Payment"
                        breadcrumbItem="Manual Enrollment Payment"
                    />
                    <Row>
                        <Col xl="12">
                            <Row>
                                <Row>
                                    <Col lg={12}>
                                        <Card style={{ height: "100vh" }}>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <StudentFilterList
                                                        selectedStudents={selectedStudents}
                                                        setSelectedStudents={setSelectedStudents}
                                                        multi={false}
                                                        text="Select..."
                                                    />
                                                </div>
                                                <div className="mb-1">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Select Courses
                                                    </Label>
                                                    <Select
                                                        required
                                                        value={selectCourses}
                                                        isMulti={false}
                                                        onChange={handleSelectCourse}
                                                        options={finalCourses}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-1">
                                                    <FormGroup>
                                                        <Label className="mt-3" for="Seminar Name">
                                                            Transaction Id
                                                        </Label>
                                                        <Input name="speaker" onChange={(e) => { setTransactionId(e.target.value) }} />
                                                    </FormGroup>
                                                </div>
                                                <div className="mb-1">
                                                    <FormGroup>
                                                        <Label className="mt-3" for="Seminar Name">
                                                            Transaction Number
                                                        </Label>
                                                        <Input name="speaker" onChange={(e) => { setTransactionNumber(e.target.value) }} />
                                                    </FormGroup>
                                                </div>
                                                <div className="mb-1">
                                                    <FormGroup>
                                                        <Label className="mt-3" for="Seminar Name">
                                                            Transaction Method
                                                        </Label>
                                                        <Input
                                                            name="zone"
                                                            type="select"
                                                            // onChange={e => setZone(e.target.value)}
                                                            onChange={(e) => { setTransactionMethod(e.target.value) }}
                                                        >
                                                            {transactionMethods?.map(seminar => {
                                                                return (
                                                                    <>
                                                                        <option key={seminar?._id} value="" hidden>
                                                                            Select...
                                                                        </option>
                                                                        <option value={seminar?.method}>{seminar?.method}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </div>
                                                <div className="mb-1">
                                                    <FormGroup>
                                                        <Label className="mt-3" for="Seminar Name">
                                                            Amount
                                                        </Label>
                                                        <Input name="speaker" onChange={(e) => { setAmount(e.target.value) }} />
                                                    </FormGroup>
                                                </div>
                                                <div className="mb-1">
                                                    <FormGroup>
                                                        <Label className="mt-3" for="Seminar Name">
                                                            Note
                                                        </Label>
                                                        <Input type="textarea"
                                                            id="textarea"

                                                            maxLength="620"
                                                            rows="3" name="speaker" onChange={(e) => { setNote(e.target.value) }} />
                                                    </FormGroup>
                                                </div>
                                                <div>
                                                    <button
                                                        // disabled={
                                                        //     selectCourses?.length > 0 &&
                                                        //         selectedStudents?.length > 0
                                                        //         ? false
                                                        //         : true
                                                        // }
                                                        onClick={
                                                            // selectCourses?.length > 0 &&
                                                            //     selectedStudents?.length > 0 ?
                                                            handleSubmit

                                                        }
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(ManualEnrollPayment)
