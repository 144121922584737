import React, { useState, useEffect } from "react"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "./CertificateCorrection.css"
const CertificateCorrection = () => {
  const [courseList, setCourseList] = useState(null)
  const [courseId, setCourseId] = useState(null)
  const [email, setEmail] = useState(null)
  const [nid, setNID] = useState(null)
  const [certificateID, setCertificateID] = useState(null)
  const [studentName, setStudentName] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCourseList(data?.message)
      })
      .catch(err => console.log(err?.response?.data))
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    const body = {
      email: email,
      certificateId: certificateID,
      name: studentName,
      NID: nid,
    }

    fetch(
      `${process.env.REACT_APP_URL}/api/certificate/correction/${courseId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => res?.json())
      .then(data => {
        setLoading(false)
        if (data.success) {
          toastr.success(data?.message)
          console.log(data)
          window.open(
            `${process.env.REACT_APP_URL}/api/image/download/${data?.data?.key}`
          )
        } else {
          toastr.error(data?.message)
        }
      })
      .catch(err => toastr.error("Error"))
  }
  console.log(courseId)
  return (
    <div className="certificate_correction_div">
      <h2>Certificate correction : </h2>
      {loading ? (
        <i className="fas fa-spinner fa-pulse fa-2x "></i>
      ) : (
        <Form>
          <FormGroup>
            <Label for="SelectCourseTitle">Course Title</Label>
            <Input
              name="courseTitle"
              type="select"
              onChange={e => setCourseId(e.target.value)}
            >
              {courseList?.map(course => {
                return (
                  <>
                    <option key={course._id} value="" hidden>
                      Select
                    </option>
                    <option value={course._id}>{course.title}</option>
                  </>
                )
              })}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label className="mt-3" for="EnterEmail">
              Email
            </Label>
            <Input
              name="examDuration"
              type="email"
              placeholder={"Enter student Email"}
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mt-3" for="EnterNIDNo">
              NID No:
            </Label>
            <Input
              name="nid"
              placeholder={"Enter student NID No"}
              onChange={e => setNID(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mt-3" for="CertificateID">
              Certificate ID
            </Label>
            <Input
              name="Certificate ID"
              placeholder={"Enter Certificate ID"}
              onChange={e => setCertificateID(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mt-3" for="Name">
              Student Name
            </Label>
            <Input
              name="Name"
              placeholder={"Enter Student Name"}
              onChange={e => setStudentName(e.target.value)}
            />
          </FormGroup>
          <div className=" mt-3">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </Form>
      )}
    </div>
  )
}

export default CertificateCorrection
