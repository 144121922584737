/**
 * * Global File Upload
 */

export const imageUpload = async file => {
  const formData = new FormData()
  formData.append("image", file)

  return await fetch(`${process.env.REACT_APP_URL}/api/image/upload/public`, {
    method: "POST",
    body: formData,
  }).then(res => {
    return res
  })
}

/**
 * * File Upload
 */

export const bookUpload = async file => {
  const formData = new FormData()
  formData.append("image", file)

  return await fetch(`${process.env.REACT_APP_URL}/api/books/upload`, {
    method: "POST",
    body: formData,
  }).then(res => {
    return res
  })
}


export const publicImageUpload = async e => {
  const formData = new FormData()
  formData.append("image", e.target.files[0])

  return await fetch(`${process.env.REACT_APP_URL}/api/image/upload/public`, {
    method: "POST",
    body: formData,
  }).then(res => {
    return res
  })
}