import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Card,
    CardBody, Col, Container, Label, Row, Spinner
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { getAllStudents } from "actions/students"
import { withTranslation } from "react-i18next"
import StudentCard from "./StudentCard"

const Students = props => {
  const [students, setStudents] = useState([])
  const [studentLoading, setStudentLoading] = useState(false)
  const [selectCourses, setSelectCourses] = useState(null)
  const [loading, setLoading] = useState(true)
  const [courseList, setCourseList] = useState([])

  const [status, setStatus] = useState(false)

  console.log({ students })

  // Get all courses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        data?.message?.map(course => {
          setCourseList(prevData => [
            ...prevData,
            { label: course?.title, value: course?._id },
          ])
        })
        // setCourseList(data?.message)
      })
      .catch(err => console.log(err?.response?.data))
  }, [])

  useEffect(() => {
    setStudentLoading(true)
    fetch(
      `${process.env.REACT_APP_URL}/api/students/course/${selectCourses?.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setStudents(res?.message)
          setStudentLoading(false)
        }
      })
  }, [selectCourses])

  useEffect(() => {
    setLoading(true)
    getAllStudents()
      .then(res => res.json())
      .then(data => {
        if (data?.success) {
          setStudents(data?.message?.docs)
          setStatus(false)
          setLoading(false)
        }
      })
  }, [status])

  const courseOptions = [...new Set(courseList)]

  const handleSelectCourse = data => {
    setSelectCourses(data)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Students")}
            breadcrumbItem={props.t("Students")}
          />

          <div className="mb-3">
            <Label htmlFor="formrow-firstname-Input">
              Select course to get specific student list
            </Label>
            <Select
              required
              value={selectCourses}
              onChange={handleSelectCourse}
              options={courseOptions}
              classNamePrefix="select2-selection"
            />
            <button
              onClick={() => {
                setSelectCourses(null)
                setStatus(true)
              }}
              style={{
                marginTop: "10px",
                border: "none",
                background: "#2a3042",
                color: "white",
                padding: "5px 10px",
                borderRadius: "4px",
              }}
            >
              Clear Filter
            </button>
          </div>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      {loading || studentLoading ? (
                        <Spinner></Spinner>
                      ) : (
                        <>
                          <div
                            style={{
                              width: "100%",
                              textAlign: "right",
                              paddingBottom: "20px",
                            }}
                          >
                            {students?.length > 0 && (
                              <CSVLink data={students}>Export as csv</CSVLink>
                            )}
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                            >
                              <Table
                                id="tech-companies-1"
                                className="table table-striped table-bordered"
                              >
                                <Thead>
                                  <Tr>
                                    <Th>Name</Th>
                                    <Th data-priority="1">Email</Th>
                                    <Th data-priority="3">Mobile Number</Th>
                                    <Th data-priority="3">Status</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {students?.length > 0 &&
                                    students?.map((student, i) => (
                                      <StudentCard key={i} student={student} />
                                    ))}
                                </Tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Students)
