import React, { useState, useEffect } from "react"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"

const FBEditor = ({ prevData, htmlData, setHtmlData }) => {
  const [editorState, setEditorState] = useState("")

  const handleChange = e => {
    setEditorState(e)
  }

  useEffect(() => {
    if (prevData) {
      const contentBlock = htmlToDraft(prevData)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )
        const value = EditorState.createWithContent(contentState)
        setEditorState(value)
      }
    }
  }, [prevData])

  useEffect(() => {
    if (editorState) {
      setHtmlData(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }
  }, [editorState])

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={handleChange}
      />
    </div>
  )
}
export default FBEditor
