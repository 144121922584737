import axios from "axios"
/**
 * * This is for the auth functionality
 * * Login
 */

/**
 * * Admin Login
 */

export const adminLogin = async content => {
  return await fetch(`${process.env.REACT_APP_URL}/api/auth/signin/admin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(content),
  }).then(res => {
    return res
  })
}

/**
 * * Get Student Refresh Token
 */

export const adminRefreshToken = async content => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  const body = content
  return await axios.post(
    `${process.env.REACT_APP_URL}/api/auth/refreshtoken/student`,
    body,
    config
  )
}
/**
 * * Get list of admin */

export const getAdminList = async () => {
  return await fetch(`${process.env.REACT_APP_URL}/api/auth/get/all/admin`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}

/**
 * * Student Logged out
 */

export const logOut = () => {
  localStorage.removeItem("auth_refresh_token")
  localStorage.removeItem("auth_access_token")
}

//** Block Student from application */
export const blockStudent = async userId => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/auth/blocked/${userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}

//** Unblock Student from application */
export const unBlockStudent = async userId => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/auth/unblocked/${userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}

//** Forgot Password Mail send endpoint */
export const forgotPassword = async email => {
  return fetch(`${process.env.REACT_APP_URL}/api/admin/password-forget`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email }),
  }).then(res => {
    return res
  })
}

//** Forgot Password Mail send endpoint */
export const authResetPassword = async (email, code, newPassword) => {
  return fetch(`${process.env.REACT_APP_URL}/api/admin/password-reset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email, code, newPassword }),
  }).then(res => {
    return res
  })
}
