//** Create Post Notice */

export const createNotice = async content => {
  return await fetch(`${process.env.REACT_APP_URL}/api/notice/post`, {
    method: "POST",
    body: JSON.stringify(content),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}

//** Get single Notice */

export const getSingleNotices = async id => {
  return await fetch(`${process.env.REACT_APP_URL}/api/notice/get/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}

//** Update single Notice */

export const updateSingleNotices = async (id, content) => {
  return await fetch(`${process.env.REACT_APP_URL}/api/notice/update/${id}`, {
    method: "PUT",
    body: JSON.stringify(content),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}

//** Get All Notice */

export const getNotices = async () => {
  return await fetch(`${process.env.REACT_APP_URL}/api/notice/getall`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}

//** Delete Notice */

export const deleteNotice = async id => {
  return await fetch(`${process.env.REACT_APP_URL}/api/notice/delete/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}
