import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import { Col, Container, Row, Table, Button } from "reactstrap"
import "../Courses/course.scss"

import SeminarList from "pages/Seminar/SeminarList"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"

function BreakStringIntoLines({ longString }) {
  // Split the input text into an array of words

  let text = longString || ""
  const words = text.split(" ")

  console.log({ text })

  // Initialize an empty array to store lines of text
  const lines = []

  // Loop through the words and group them into lines of 10 words each
  for (let i = 0; i < words.length; i += 10) {
    const line = words.slice(i, i + 10).join(" ")
    lines.push(line)
  }

  // Render the lines with line breaks
  return (
    <div>
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  )
}

const AffiliateList = () => {
  const [pendingAffiliateList, setPendingAffiliateList] = useState([])

  const [paidAffiliateList, setPaidAffiliateList] = useState([])
  const [status, setStatus] = useState(false)

  const history = useHistory()

  // Get all courses

  const getAllAffiliate = async () => {
    fetch(`${process.env.REACT_APP_URL}/api/affiliate/get/all/payment`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        console.log({ data }, "from afffiliate")
        // setAffiliateList(data.data)

        let list = data.data

        let pending = []
        let paid = []

        list?.map(d => {
          if (d?.status === "PENDING") {
            pending.push(d)
          } else if (d?.status === "PAID") {
            paid.push(d)
          }
        })

        setPendingAffiliateList(pending)
        setPaidAffiliateList(paid)
      })
      .catch(err => console.log(err))
  }
  useEffect(() => {
    getAllAffiliate()
  }, [status])

  // delete specific course

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Affiliate" breadcrumbItem="Affiliate List" />
          {/* <Button
                        onClick={() => history.push(`/seminar/add/new`)}
                        color="primary"
                        className="btn btn-primary "
                    >
                        Add New Seminar
                    </Button> */}
          <Row>
            <Col lg="12">
              <div className="">
                <h5>Pending List</h5>
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Serial</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Bkash Number</th>
                        <th scope="col">Request Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                       pendingAffiliateList?.length > 0 ? pendingAffiliateList?.map((d, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{d?.amount}</td>
                              <td>
                                {d?.bkashNumber
                                  ? d?.bkashNumber
                                  : "No number provided"}
                              </td>
                              <td>{d?.requestDate.slice(0, 10)}</td>
                              <td>{d?.status}</td>
                              <td
                              style={{cursor: "pointer"}}
                                onClick={() => {
                                  history.push(`/affiliate/${d?._id}`)
                                }}
                              >
                                <i class="bx bx-edit-alt"></i>
                              </td>
                            </tr>
                          </>
                        )
                      }) : <h5>No pending list now</h5>}
                    </tbody>
                  </Table>
                </div>

                <h5>Paid List</h5>
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Serial</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Bkash Number</th>
                        <th scope="col">Request Date</th>
                        <th scope="col">Paid Date</th>
                        <th scope="col">Transaction Id</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paidAffiliateList?.length > 0 ? 
                      paidAffiliateList?.map((d, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{d?.amount}</td>
                              <td>
                                {d?.bkashNumber
                                  ? d?.bkashNumber
                                  : "No number provided"}
                              </td>
                              <td>{d?.requestDate?.slice(0, 10)}</td>
                              <td>{d?.paidDate?.slice(0, 10)}</td>
                              <td>{d?.transactionId}</td>
                              <td>
                                {/* {d?.comment?.length >= 30 ? (
                                  <BreakStringIntoLines text={d?.comment} />
                                ) : ( */}
                                {d?.comment}
                              </td>
                              <td>{d?.status}</td>
                            </tr>
                          </>
                        )
                      }) : <h5>No paid list now</h5>}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AffiliateList)
