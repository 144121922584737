import React, { useEffect, useState } from "react"
import renderHTML from "react-render-html"
import moment from "moment"
import { getInstructors } from "actions/instructor"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function SubscriberList({ subscriber, date }) {



    return (
        <tr>
            <td>
                <h5 style={{ width: "300px" }} className="text-truncate font-size-14">
                    <span style={{ fontSize: "20px" }} className="text-dark">
                        {subscriber}
                    </span>
                </h5>
            </td>
            <td>
                <span>
                    {date}
                </span>
            </td>

        </tr>
    )
}

export default SubscriberList
