import React, { useEffect, useState } from "react"
import renderHTML from "react-render-html"
import moment from "moment"
import { getInstructors } from "actions/instructor"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function SeminarList({ seminar, setStatus, status, key }) {
  const history = useHistory()

  const handleDelete = seminarId => {
    fetch(`${process.env.REACT_APP_URL}/api/seminar/delete/${seminarId}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          toastr.success(res?.message, "seminar")
        } else {
          toastr.error(res?.message, "Seminar")
        }

        console.log(res)
        setStatus(!status)
      })
      .catch(err => console.log(err))
  }

  return (
    <tr>
      <td>
        <h5 style={{ width: "300px" }} className="text-truncate font-size-14">
          <span style={{ fontSize: "20px" }} className="text-dark">
            {seminar?.title}
          </span>
        </h5>
      </td>
      <td>
        <span>
          {seminar?.year}-{seminar?.month}-{seminar?.date}
        </span>
      </td>
      <td>
        <span>{seminar?.time}</span>
      </td>
      <td>
        <span>{seminar?.zone}</span>
      </td>
      <td>
        <span>{seminar?.type}</span>
      </td>
      {/* <td>
        <span>{instructor?.label}</span>
      </td> */}
      <td>
        <button
          style={{ marginRight: "10px" }}
          onClick={() => history.push(`/seminars/edit/${seminar?._id}`)}
          className="btn btn-primary w-md"
        >
          Edit
        </button>
        <button
          onClick={() =>
            window.confirm("Do you want to delete this seminar?")
              ? handleDelete(seminar?._id)
              : null
          }
          className="btn btn-danger w-md"
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

export default SeminarList
