export const getAllStudents = async () => {
  return await fetch(`${process.env.REACT_APP_URL}/api/student/get`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}

export const getStudentById = async studentId => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/student/get/${studentId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}
