import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card, CardBody, Col,
    Container, Label, Row
} from "reactstrap"
// Form Editor

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { createNotice } from "actions/notice"
import FBEditor from "components/Editor/Editor"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//redux

const AddNewNotice = props => {
  const [text, setText] = useState("")

  const [uploadStatus, setUploadStatus] = useState(false)
  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()

    const body = {
      text,
    }
    createNotice(body)
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success("Notice Created!!", "Notice")
          history.push("/notice")
        } else {
          toastr.error(data?.message?.message, "Notice")
        }
      })
      .catch(err => console.log(err))
  }

  const handleBodyChange = e => {
    setText(e)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Notice")}
            breadcrumbItem={props.t("Add new notice")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Notice text
                              </Label>

                              <FBEditor htmlData={text} setHtmlData={setText} />
                            </div>

                            <div>
                              <button
                                disabled={uploadStatus ? true : false}
                                type="submit"
                                className="btn btn-primary w-md mt-3"
                              >
                                Create notice
                              </button>
                            </div>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddNewNotice.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

AddNewNotice.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(AddNewNotice)
