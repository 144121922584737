export const getInstructors = async () => {
  return await fetch(`${process.env.REACT_APP_URL}/api/instructor/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}
