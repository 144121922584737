import React, { useState, useEffect, useRef } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import useOnClickOutside from "./OutSide"
import { Input } from "reactstrap"

const StudentFilterList = ({
  selectedStudents,
  setSelectedStudents,
  multi,
  text,
}) => {
  const [focus, setFocus] = useState(false)
  const [filterStudents, setFilterStudents] = useState([])
  const [textStudent, setTextStudent] = useState("")

  // const [selectedBadges, setSelectedBadges] = useState([]);
  const dropDownRef = useRef()
  useOnClickOutside(dropDownRef, () => setFocus(false))

  const [limit, setLimit] = useState(10)
  const [totalNumber, setTotalNumber] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setHasMore(true)
    const queryString =
      textStudent?.length > 0
        ? `?search=${textStudent}&limit=${limit}`
        : `?limit=${limit}`
    fetch(`${process.env.REACT_APP_URL}/api/student/get${queryString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setFilterStudents(res?.message?.docs)
          setTotalNumber(res?.message?.totalDocs)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [textStudent, limit])

  useEffect(() => {
    const filterBadgesData = filterStudents?.filter(fs =>
      fs?.email?.toLowerCase().includes(textStudent?.toLowerCase())
    )
    setFilterStudents(filterBadgesData)
  }, [textStudent])

  const fetchMoreData = async () => {
    const queryString =
      textStudent?.length > 0
        ? `?search=${textStudent}&limit=${limit}`
        : `?limit=${limit}`
    setLimit(limit + 10)
    await fetch(`${process.env.REACT_APP_URL}/api/student/get${queryString}`)
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setFilterStudents(res?.message?.docs)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (!loading) {
      if (limit > totalNumber) {
        setHasMore(false)
      }
    }
  }, [loading, totalNumber, limit])

  //** Check Badges */

  const checkedBadgesDataHasOrNot = badge => {
    return selectedStudents?.length > 0 &&
      selectedStudents?.find(d => d?._id === badge)
      ? true
      : false
  }

  //** Badges Add or Remove */
  const BadgesAddOrRemove = badge => {
    //** If it is multi select */
    if (multi) {
      const findData =
        selectedStudents?.length > 0
          ? selectedStudents?.find(d => d === badge)
          : undefined

      if (findData !== undefined) {
        const filterData = selectedStudents?.filter(d => d !== badge)
        setSelectedStudents(filterData)
      } else {
        setSelectedStudents(prevData => [...prevData, badge])
      }
    } else {
      //** If it is single select */
      setSelectedStudents([badge])

      if (selectedStudents?.length > 0 && selectedStudents[0] === badge) {
        setSelectedStudents([])
      } else {
        setSelectedStudents([badge])
      }
    }
  }

  console.log({filterStudents});
  return (
    <div className="w-full" ref={dropDownRef}>
      <p style={{ marginBottom: "5px", fontWeight: "500" }}>Select Students</p>
      <div
        style={{
          background: "white",
          border: "1px solid lightgray",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 10px",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <div
          onClick={() => setFocus(true)}
          style={{ display: "flex", flexWrap: "wrap", flex: "1 1 0%" }}
        >
          {selectedStudents?.length <= 0 ? (
            <span
              style={{ fontSize: "14px" }}
              className="text-sm text-gray-400"
            >
              {text}
            </span>
          ) : (
            selectedStudents?.map(sb => (
              <button
                onClick={() => BadgesAddOrRemove(sb)}
                style={{
                  fontSize: "14px",
                  borderRadius: "3px",
                  background: "hsl(0,0%,90%)",
                  color: "black",
                  border: "none",
                  padding: "5px 14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
            {sb?.email}
                <i
                  style={{ fontSize: "17px", marginLeft: "5px", color: "red" }}
                  className="bx bx-x"
                ></i>
              </button>
            ))
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderLeft: "1px solid lightgray",
          }}
        >
          <span
            onClick={() => setFocus(!focus)}
            style={{
              fontSize: "20px",
              display: "flex",
              color: "gray",
              marginLeft: "5px",
            }}
          >
            <i className="bx bx-chevron-down"></i>
          </span>
        </div>
      </div>
      {focus && (
        <div>
          <div
            style={{
              border: "2px solid lightgray",
              borderRadius: "5px",
              marginTop: "3px",
            }}
            className="p-3"
          >
            <div
              style={{ background: "#F1F4F9" }}
              className="rounded py-2 px-3 mb-3"
            >
              <input
                type="text"
                placeholder="Search for Badge"
                value={textStudent}
                onChange={e => setTextStudent(e.target.value)}
                style={{
                  border: "none",
                  background: "transparent",
                  width: "100%",
                  fontSize: "15px",
                }}
              />
            </div>

            <div>
              <InfiniteScroll
                dataLength={filterStudents?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h5>Loading...</h5>}
                height={250}
                scrollableTarget="badge"
              >
                {filterStudents?.length > 0 &&
                  filterStudents.map((st, i) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                        paddingLeft: "10px",
                      }}
                      key={i}
                    >
                      {/* Conditionally render checkbox based on 'multi' prop */}
                      {multi ? (
                        <Input
                          type="checkbox"
                          onClick={() => {
                            BadgesAddOrRemove(st)
                          }}
                          checked={st?._id ? checkedBadgesDataHasOrNot(st?._id) : ""}
                          id={st?._id}
                        />
                      ) : (
                        <input
                          type="radio"
                          name="student"
                          onClick={() => {
                            BadgesAddOrRemove(st)
                          }}
                          checked={st?._id ? checkedBadgesDataHasOrNot(st?._id) : ""}
                          id={st?._id}
                        />
                      )}
                      <label
                        style={{
                          fontSize: "15px",
                          marginBottom: "0px",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        htmlFor={st?._id}
                      >
                        {st?.email}
                      </label>
                    </div>
                  ))}
                {/* {filterStudents?.length > 0 &&
                  filterStudents.map((st, i) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                        paddingLeft: "10px",
                      }}
                      key={i}
                    >
                      <Input
                        type="checkbox"
                        onClick={() => {
                          BadgesAddOrRemove(st?._id)
                        }}
                        checked={
                          st?._id ? checkedBadgesDataHasOrNot(st?._id) : ""
                        }
                        id={st?._id}
                      />
                      <label
                        style={{
                          fontSize: "15px",
                          marginBottom: "0px",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        htmlFor={st?._id}
                      >
                        {st?._id}
                      </label>
                    </div>
                  ))} */}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default StudentFilterList
