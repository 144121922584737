import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"

//redux

const AddNewCategory = props => {
  const { id } = useParams()
  const [title, setTitle] = useState("")
  const [categoryName, setCategoryName] = useState(null)
  const [courses, setCourses] = useState([])
  const [courseOption, setCourseOption] = useState([])
  const [selectedCourse, setSelectedCourse] = useState([])
  const [finalCourse, setFinalCourse] = useState([])
  const [info, setInfo] = useState([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/featured`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data?.success) {
          console.log(data?.message?.[0]?.course)
          setTitle(data?.message?.[0]?.featureCourseTitle)
          setInfo(data?.message[0]?.course)
        }
      })
      .catch(err => console.log(err?.response?.data))

    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCourses(data?.message)
      })
      .catch(err => console.log(err?.response?.data))
  }, [])

  useEffect(() => {
    if (info?.length > 0) {
      info?.map(c => {
        setSelectedCourse(prevC => [
          ...prevC,
          { label: c?.title, value: c?._id },
        ])
      })
    }
  }, [info])

  useEffect(() => {
    courses?.length > 0 &&
      courses?.map(course =>
        setCourseOption(prevCourse => [
          ...prevCourse,
          { label: course?.title, value: course?._id },
        ])
      )
  }, [courses])

  const handleSubmit = async e => {
    e.preventDefault()
    await fetch(`${process.env.REACT_APP_URL}/api/course/featured/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        featureCourseTitle: title,
        course: [...new Set(finalCourse)],
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data?.success) {
          toastr.error(data?.message)
        } else if (data?.success) {
          toastr.success(data?.message)
        }
      })
      .catch(err => console.log(err))
    setCategoryName("")
  }

  function handleMulti(e) {
    setSelectedCourse(e)
    setFinalCourse([])
  }

  useEffect(() => {
    selectedCourse?.map(c => {
      setFinalCourse(prvC => [...prvC, c?.value])
    })
  }, [selectedCourse])

  const categoryNames = [
    {
      options: courseOption,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Category")}
            breadcrumbItem={props.t("Dynamic Section")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label htmlFor="">Section title</label>
                            <input
                              type="text"
                              name=""
                              className="form-control"
                              id=""
                              placeholder="Enter section title"
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                            />
                          </div>

                          <div className="mb-3">
                            <label className="control-label">
                              Select Courses for featured
                            </label>
                            <Select
                              required
                              readOnly
                              value={selectedCourse}
                              isMulti={true}
                              onChange={e => {
                                handleMulti(e)
                              }}
                              options={categoryNames}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                          <div>
                            <button
                              disabled={
                                selectedCourse?.length <= 0 ? true : false
                              }
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Update
                            </button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AddNewCategory)
