import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import { Col, Container, Row, Table, Button } from "reactstrap"
// import "./course.scss"
import renderHTML from "react-render-html"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const CourseList = props => {
  const [courseList, setCourseList] = useState([])
  const [status, setStatus] = useState(false)

  const history = useHistory()

  // /api/bundle/getall
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/bundle/getall`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCourseList(data?.message)
        setStatus(false)
      })
  }, [status])

  // delete specific course

  const handleDelete = courseId => {
    fetch(`${process.env.REACT_APP_URL}/api/bundle/delete/${courseId}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Course")
          setStatus(true)
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Courses" breadcrumbItem="Bundle Course List" />
          <Button
            onClick={() => history.push(`/bundle-courses/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Bundle Course
          </Button>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "100px" }}>
                          #
                        </th>

                        <th scope="col">Bundle Name</th>
                        <th scope="col">Bundle Short Description</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(courseList, (course, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={
                                course?.image
                                  ? `${process.env.REACT_APP_URL}/api/image/download/${course?.image}`
                                  : ""
                              }
                              alt=""
                              className="avatar-sm"
                            />
                          </td>
                          <td>
                            <p className="text-muted mb-0">{course?.name}</p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              {course?.short_description}
                            </p>
                          </td>

                          <td>
                            <button
                              style={{ marginRight: "10px" }}
                              className="btn btn-primary w-md"
                              onClick={() =>
                                history.push(
                                  `/bundle-courses/edit/${course?._id}`
                                )
                              }
                            >
                              Edit
                            </button>
                            <button
                              onClick={() =>
                                window.confirm(
                                  "Do you want to delete this bundle course?"
                                )
                                  ? handleDelete(course?._id)
                                  : null
                              }
                              className="btn btn-danger w-md"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CourseList)
