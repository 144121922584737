import React, { useState, useEffect } from "react"
import CurriculumLesson from "./CurriculumLesson"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { sectionDragAndDrop } from "../../actions/course"
import {
  UncontrolledTooltip,
  Modal,
  Row,
  Card,
  CardBody,
  Col,
  Label,
  Input,
} from "reactstrap"

function CurriculumSection({
  sections,
  setSections,
  addStatus,
  setAddStatus,
  courseId,
  index,
  sec,
  handleDeleteSection,
  sectionReArrange,
  setSectionReArrange,
}) {
  const [reArrange, setReArrange] = useState(false)
  const [expand, setExpand] = useState(true)
  const [lessons, setLessons] = useState([])
  const [lessonName, setLessonName] = useState("")
  const [edit, setEdit] = useState(false)
  const [title, setTitle] = useState("")

  const [sectionDetails, setSectionDetails] = useState(null)

  // Get the section details

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/chapter/get/${sec} `, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => setSectionDetails(data?.message))
      .catch(err => console.log(err))
  }, [sec, addStatus])

  // Get all lesson under this section

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/lesson/getall/${sec}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => {
        setLessons(data?.message[0]?.lessons)
      })
      .catch(err => console.log(err))
  }, [sec, addStatus])

  const handleSubmit = e => {
    e.preventDefault()

    const body = {
      lesson_title: lessonName,
      lesson_duration: "",
      lesson_url: "",
      lesson_description: "",
      lesson_attatchment: "",
    }
    fetch(`${process.env.REACT_APP_URL}/api/lesson/update/${sec}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Lesson")
        setAddStatus(true)
      })
      .catch(err => console.log(err))

    // setLessons(prevSection => [...prevSection, lessonName])
    setLessonName("")
  }

  /**
   * * Lesson View
   */

  const handleViewLesson = () => {
    //
  }
  /**
   * * Lesson Edit
   */

  const handleEditLesson = () => {
    //
  }

  /**
   *
   * * Delete Lesson
   */
  const handleDeleteLesson = lessonId => {
    fetch(`${process.env.REACT_APP_URL}/api/lesson/delete/${sec}/${lessonId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Lesson")
        setAddStatus(true)
      })
  }

  useEffect(() => {
    setTitle(sectionDetails?.chapter_title)
  }, [sectionDetails])

  //** Section title update */

  const handleTitleUpdate = () => {
    fetch(`${process.env.REACT_APP_URL}/api/chapter/update/${sec}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chapter_title: title,
      }),
    })
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Section")
        setEdit(false)
        // setAddStatus(true)
      })
  }

  const handleDrag = (e, index) => {
    e.dataTransfer.setData("itemIndex", index)
  }

  const handleDrop = (e, index) => {
    const movingItemIndex = Number(e.dataTransfer.getData("itemIndex"))
    const targetIndex = index

    let allSections = sections

    let movingItem = allSections[movingItemIndex]
    allSections.splice(movingItemIndex, 1)

    allSections.splice(targetIndex, 0, movingItem)

    setSections(allSections)
    setSectionReArrange(!sectionReArrange)

    console.log({ allSections })

    sectionDragAndDrop(allSections, courseId)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div
      draggable
      onDragStart={e => handleDrag(e, index)}
      onDrop={e => handleDrop(e, index)}
      className="curriculum__section__wrapper"
    >
      <div className="curriculum__section__title">
        <div onClick={() => setExpand(!expand)} className="hambar__title">
          <span className="hambar">
            <i className="bx bx-menu"></i>
          </span>
          <span className="title">{title}</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            onClick={() => setEdit(true)}
            style={{ marginRight: "15px" }}
            className="flex-fill"
          >
            <span
              style={{ marginLeft: "10px", fontSize: "20px" }}
              id={"edit" + 1}
            >
              <i className="bx bxs-edit"></i>
              <UncontrolledTooltip placement="top" target={"edit" + 1}>
                Edit
              </UncontrolledTooltip>
            </span>
          </div>
          <span onClick={() => setExpand(!expand)} className="down">
            <i className="bx bx-chevron-down"></i>
          </span>
        </div>
      </div>
      {expand && (
        <div onDragOver={e => e.preventDefault()} className="lesson">
          {lessons?.length > 0 &&
            lessons?.map((les, i) => (
              <CurriculumLesson
                lessons={lessons}
                setLessons={setLessons}
                handleViewLesson={handleViewLesson}
                handleDeleteLesson={handleDeleteLesson}
                key={i}
                setReArrange={setReArrange}
                reArrange={reArrange}
                les={les}
                sectionId={sec}
                addStatus={addStatus}
                setAddStatus={setAddStatus}
                index={i}
              />
            ))}

          <form onSubmit={handleSubmit}>
            <div className="lesson__input">
              <span>
                <i className="bx bx-plus"></i>
              </span>
              <input
                type="text"
                value={lessonName}
                onChange={e => setLessonName(e.target.value)}
                placeholder="Create new lesson"
              />
            </div>
          </form>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <span
              onClick={() =>
                window.confirm("Do you want to delete this section?")
                  ? handleDeleteSection(sec)
                  : null
              }
              className="section__delete"
            >
              Delete
            </span>
          </div>
        </div>
      )}

      <Modal size="lg" isOpen={edit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {title}
          </h5>
          <button
            onClick={() => {
              setEdit(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">Title</Label>
                  <Input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Course Title"
                  />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <button
                    onClick={handleTitleUpdate}
                    className="btn btn-primary w-md"
                  >
                    Save
                  </button>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default CurriculumSection
