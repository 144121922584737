import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  FormGroup,
} from "reactstrap"

// Form Editor

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { imageUpload } from "actions/imageUpload"
import { getInstructors } from "actions/instructor"
import FBEditor from "components/Editor/Editor"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//redux

const AddNewCourse = props => {
  /**
   * * State for course data
   */
  const [title, setTitle] = useState("")
  const [slug, setSlug] = useState("")
  const [fakeStudentEnrolled, setFakeStudentEnrolled] = useState(0)
  const [regularPrice, setRegularPrice] = useState(0)
  const [salePrice, setSalePrice] = useState(0)
  const [instructor, setInstructor] = useState("")
  const [thumbnailImage, setThumbnailImage] = useState([])
  const [details, setDetails] = useState("")
  const [shortDescription, setShortDescription] = useState(null)
  const [paidCourse, setPaidCourse] = useState(true)
  const [liveClass, setLiveClass] = useState(true)
  const [textareabadge, settextareabadge] = useState(0)
  const [textcount, settextcount] = useState(0)
  const [allCategories, setAllCategories] = useState([])
  const [categories, setCategories] = useState([])
  const [categoryOption, setCategoryOption] = useState([])
  const [instructors, setInstructors] = useState([])
  const [uploadStatus, setUploadStatus] = useState(false)
  const [finalCategory, setFinalCategory] = useState([])
  const [image, setImage] = useState(null)
  const [cardImage, setCardImage] = useState(null)
  const [pageImage, setPageImage] = useState(null)
  const [courseDuration, setCourseDuration] = useState("")
  const [profileImageError, setProfileImageError] = useState(null)
  const [cardImageError, setCardImageError] = useState(null)
  const [publish, setPublish] = useState(true)
  const [courseValidDays, setCourseValidDays] = useState("")
  const [courseNotice, setCourseNotice] = useState("")
  const [visibleNotice, setVisibleNotice] = useState(false)

  const [noticeDateVisible, setNoticeDateVisible] = useState(false);
  const [noticeEndDate, setNoticeEndDate] = useState("");
  const [enrollOff, setEnrollOff] = useState(false);

  const [benifits, setBenifits] = useState([{ benifit: "" }])
  const [courseDataGet, setCourseDataGet] = useState(false)

  const [trainerPercentage, setTrainerPercentage] = useState(0);

  const history = useHistory()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/category/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => setAllCategories(data?.message))
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    allCategories?.length > 0 &&
      allCategories?.map(category =>
        setCategoryOption(prevCategory => [
          ...prevCategory,
          { label: category?.category_name, value: category?._id },
        ])
      )
  }, [allCategories])

  useEffect(() => {
    categories?.length > 0 &&
      categories?.map(category => {
        setFinalCategory(prevCategory => [...prevCategory, category?.value])
      })
  }, [categories])

  useEffect(() => {
    getInstructors()
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          res?.message?.length > 0 &&
            res?.message?.map(ins => {
              setInstructors(prevIns => [
                ...prevIns,
                { label: ins?.fullName, value: ins?._id },
              ])
            })
        }
      })
  }, [])

  const optionGroup = [
    {
      options: instructors,
    },
  ]

  const categoryNames = [
    {
      options: categoryOption,
    },
  ]
  function handleAcceptedFiles(files) {
    setProfileImageError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {

          console.log("public", data);
          if (data?.success === undefined) {
            setImage(data?.Key)
            setPageImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setImage(data?.Key)
              setPageImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setProfileImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }


  function handleAcceptedCardFiles(files) {
    setCardImageError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setCardImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setCardImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setCardImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  // const fileExtension =
  //   image !== null &&
  //   image?.substr(image?.lastIndexOf(".") + 1);
  // const certificateViewer = () => {
  //   if (fileExtension !== undefined) {
  //     if (
  //       fileExtension === "png" ||
  //       fileExtension === "PNG" ||
  //       fileExtension === "jpg" ||
  //       fileExtension === "JPG" ||
  //       fileExtension === "jpeg" ||
  //       fileExtension === "JPEG"
  //     ) {
  //       return (
  //         <img
  //           // width={200}
  //           style={{
  //             width: "100px",
  //             height: "90px",
  //           }}
  //           src={image}
  //           // src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
  //           alt="SupportImage"
  //         />
  //       );
  //     } else {
  //       return (
  //         <>
  //           <a
  //             style={{
  //               background: "#38aba1",
  //               display: "inline-block",
  //               color: "white",
  //               padding: "10px 20px",
  //               borderRadius: "6px",
  //             }}
  //             href={`${process.env.REACT_APP_DEV_URL}/api/image/download/${image}`}
  //             download
  //           >
  //             Download Your Support Image
  //           </a>
  //         </>
  //       );
  //     }
  //   }
  // };
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (e.keyCode === 13) {
      e.preventDefault()
    }

    let finalBenifits = []
    benifits.map(benifit => {
      if (benifit.benifit !== "") {
        finalBenifits.push(benifit)
      }
    })

    const body = {
      title,
      slug,
      course_description: details,
      category: [...new Set(finalCategory)],
      instructorId: instructor?.value,
      regular_price: Number(regularPrice),
      sale_price: Number(salePrice),
      enrollement_count: Number(fakeStudentEnrolled),
      tag: ["C", "programming"],
      course_paid: paidCourse,
      course_live: liveClass,
      course_short_description: shortDescription,
      course_duration: courseDuration,
      upload_featured_image: image,
      course_publish: publish,
      benefits: [...finalBenifits],
      validDays: courseValidDays,
      cardImage: cardImage,
      pageImage: pageImage,
      courseNotice: courseNotice,
      visibleNotice: visibleNotice,
      noticeEndDate: noticeEndDate,
      noticeDateVisible: noticeDateVisible,
      enrollOff: enrollOff,
      trainerPercentage: trainerPercentage
    }

    fetch(`${process.env.REACT_APP_URL}/api/course/post`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Course")
          history.push("/courses")
        } else {
          toastr.error(data?.message, "Course")
        }
      })
      .catch(err => toastr.error(err?.response?.data?.message, "Course"))
  }
  const handleBodyChange = e => {
    setDetails(e)
  }

  function textareachange(event) {
    setShortDescription(event.target.value)
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  function handleMulti(e) {
    setCategories(e)
    setFinalCategory([])
  }

  const handleWheel = e => {
    e.preventDefault()
  }


  // course benifits

  const handleInputChange = (e, index) => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
    const { name, value } = e.target
    const list = [...benifits]
    list[index][name] = value
    setBenifits(list)
  }

  const handleRemoveClick = index => {
    const list = [...benifits]
    list.splice(index, 1)
    setBenifits(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setBenifits([
      ...benifits,
      {
        benifit: "",
      },
    ])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Courses")}
            breadcrumbItem={props.t("Add new course")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">Title</Label>
                              <Input
                                required
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Course Title"
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-slug-Input">Slug</Label>
                              <Input
                                required
                                value={slug}
                                onChange={e => setSlug(e.target.value)}
                                type="text"
                                className="form-control"
                                id="formrow-slug-Input"
                              />
                            </div>
                            <Row>
                              <Col lg={12} style={{ marginTop: "0px", marginBottom: "10px" }}>
                                <FBEditor htmlData={details} setHtmlData={setDetails} />
                              </Col>
                            </Row>
                            <Row>
                              <div className="mt-2 mb-3">
                                <Label>Short Description</Label>
                                <Input
                                  required
                                  type="textarea"
                                  id="textarea"
                                  onChange={e => textareachange(e)}
                                  maxLength="620"
                                  rows="3"
                                  placeholder="Short description has a limit of 620 chars."
                                />
                                {textareabadge && (
                                  <span className="badgecount badge bg-success">
                                    {textcount} / 620
                                  </span>
                                )}
                              </div>
                            </Row>
                            {benifits?.map((x, i) => (
                              <div key={i} className="box">
                                <Form>
                                  <FormGroup>
                                    <Label className="mt-3 fw-bold" htmlFor={`benifit-${i}`}>
                                      Benefit NO : {i + 1}
                                    </Label>
                                    <Input
                                      name="benifit"
                                      id={`benifit-${i}`}
                                      placeholder="Course Benefit"
                                      value={x.benifit}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </FormGroup>
                                </Form>
                                <br />
                                <div>
                                  {benifits.length !== 1 && (
                                    <button
                                      onClick={() =>
                                        window.confirm("Do you want to delete this benefit?")
                                          ? handleRemoveClick(i)
                                          : null
                                      }
                                      className="btn btn-danger w-md"
                                    >
                                      <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                                      <span>Delete this benefit</span>
                                    </button>
                                  )}
                                  {benifits.length - 1 === i && (
                                    <button
                                      className={benifits.length === 1 ? "addButton" : "addButton ms-3"}
                                      onClick={handleAddClick}
                                    >
                                      <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                                      <span>Add New benefit</span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div className="mb-3 mt-3">
                              <Label htmlFor="formrow-fakeStudentEnrolled-Input">Fake Student Enrolled</Label>
                              <Input
                                required
                                value={fakeStudentEnrolled}
                                onChange={e => setFakeStudentEnrolled(e.target.value)}
                                onWheel={e => e.target.blur()}
                                type="number"
                                min="0"
                                className="form-control"
                                id="formrow-fakeStudentEnrolled-Input"
                                placeholder="0"
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="courseDuration">Course Duration</Label>
                              <Input
                                required
                                value={courseDuration}
                                onChange={e => setCourseDuration(e.target.value)}
                                type="text"
                                className="form-control"
                                id="courseDuration"
                                placeholder="Ex: 15 hr 30 mints"
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="courseValidDays">Course Valid Days</Label>
                              <Input
                                required
                                value={courseValidDays}
                                onChange={e => setCourseValidDays(e.target.value)}
                                type="text"
                                className="form-control"
                                id="courseValidDays"
                                placeholder="Days"
                              />
                            </div>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-regularPrice-Input">Regular Price</Label>
                                  <Input
                                    required
                                    onWheel={e => e.target.blur()}
                                    value={regularPrice}
                                    onChange={e => setRegularPrice(e.target.value)}
                                    min="0"
                                    type="number"
                                    className="form-control"
                                    id="formrow-regularPrice-Input"
                                    placeholder="0"
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-salePrice-Input">Sale Price</Label>
                                  <Input
                                    required
                                    onWheel={e => e.target.blur()}
                                    value={salePrice}
                                    onChange={e => setSalePrice(e.target.value)}
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    id="formrow-salePrice-Input"
                                    placeholder="0"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="mb-3">
                              <Label>Instructors</Label>
                              <Select
                                required
                                value={instructor}
                                onChange={data => setInstructor(data)}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label htmlFor="formrow-trainer-percentage">Trainer Percentage</Label>
                              <Input
                                required
                                value={trainerPercentage}
                                onChange={e => setTrainerPercentage(e.target.value)}
                                type="text"
                                className="form-control"
                                id="formrow-trainer-percentage"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="control-label">Select Categories</label>
                              <Select
                                required
                                value={categories}
                                isMulti
                                onChange={handleMulti}
                                options={categoryNames}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                            <div>
                              <label htmlFor="featuredImage">Upload Featured Image</label>
                              <Dropzone onDrop={handleAcceptedFiles}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                      <input required {...getInputProps()} id="featuredImage" />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>Drop files here or click to upload.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              {profileImageError && (
                                <span style={{ display: "block", marginTop: "10px", color: "red" }}>
                                  {profileImageError}
                                </span>
                              )}
                              {image && (
                                <div className="mt-3">
                                  <img
                                    style={{ width: "200px", height: "150px" }}
                                    src={`${process.env.REACT_APP_URL}/api/image/download/${image}`}
                                    alt=""
                                  />
                                  <div className="d-flex justify-content-start mt-1">
                                    <button onClick={() => setImage(null)} className="btn btn-danger">
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="mt-2">
                              <label htmlFor="cardImage">Upload Card Image</label>
                              <Dropzone onDrop={handleAcceptedCardFiles}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                      <input required {...getInputProps()} id="cardImage" />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>Drop files here or click to upload.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              {cardImageError && (
                                <span style={{ display: "block", marginTop: "10px", color: "red" }}>
                                  {cardImageError}
                                </span>
                              )}
                              {cardImage && (
                                <div className="mt-3">
                                  <img
                                    style={{ width: "200px", height: "150px" }}
                                    src={`${process.env.REACT_APP_URL}/api/image/download/${cardImage}`}
                                    alt=""
                                  />
                                  <div className="d-flex justify-content-start mt-1">
                                    <button onClick={() => setCardImage(null)} className="btn btn-danger">
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Course Notice Visible
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="visibleNotice"
                                  id="free"
                                  value="false"
                                  checked={!visibleNotice ? true : false}
                                  onChange={e => setVisibleNotice(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="free"
                                >
                                  False
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="visibleNotice"
                                  id="paid"
                                  value="True"
                                  checked={visibleNotice ? true : false}
                                  onChange={e => setVisibleNotice(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="paid"
                                >
                                  True
                                </label>
                              </div>
                            </div>

                            <div className="mb-3">
                              <Label htmlFor="formrow-password-Input">
                                Course Notice
                              </Label>
                              <Row>
                                <Col
                                  lg={12}
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <FBEditor
                                    htmlData={courseNotice}
                                    setHtmlData={setCourseNotice}
                                  />
                                </Col>
                              </Row>
                            </div>

                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Notice End Date Visible
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="noticeEndDateVisible"
                                  id="free"
                                  value="false"
                                  checked={!noticeDateVisible ? true : false}
                                  onChange={e => setNoticeDateVisible(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="free"
                                >
                                  False
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="noticeEndDateVisible"
                                  id="paid"
                                  value="True"
                                  checked={noticeDateVisible ? true : false}
                                  onChange={e => setNoticeDateVisible(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="paid"
                                >
                                  True
                                </label>
                              </div>
                            </div>
                            <FormGroup>
                              <Label for="exampleDate">
                                Notice Ending Date
                              </Label>
                              <Input
                                id="exampleDate"
                                name="endingDate"
                                placeholder="Notice End Date"
                                type="date"
                                onChange={e => setNoticeEndDate(e.target.value)}
                              />
                            </FormGroup>

                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Enrollment Off
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="newEnrollment"
                                  id="free"
                                  value="false"
                                  checked={!enrollOff ? true : false}
                                  onChange={e => setEnrollOff(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="free"
                                >
                                  False
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="newEnrollment"
                                  id="paid"
                                  value="True"
                                  checked={enrollOff ? true : false}
                                  onChange={e => setEnrollOff(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="paid"
                                >
                                  True
                                </label>
                              </div>
                            </div>
                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Free/Paid Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="free-paid-status"
                                  id="free"
                                  value="Free"
                                  checked={!paidCourse ? true : false}
                                  onChange={e => setPaidCourse(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="free"
                                >
                                  Free
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="free-paid-status"
                                  id="paid"
                                  value="Paid"
                                  checked={paidCourse ? true : false}
                                  onChange={e => setPaidCourse(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="paid"
                                >
                                  Paid
                                </label>
                              </div>
                            </div>


                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Class Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="class-status"
                                  id="live"
                                  value="Live"
                                  onChange={e => setLiveClass(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="live"
                                >
                                  Live Class
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="class-status"
                                  id="prerecord"
                                  value="Prerecord"
                                  onChange={e => setLiveClass(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="prerecord"
                                >
                                  Prerecord Class
                                </label>
                              </div>
                            </div>
                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Publish Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="draft"
                                  value="Draft"
                                  checked={!publish ? true : false}
                                  onChange={e => setPublish(false)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="draft"
                                >
                                  Draft
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="Publish"
                                  value="Publish"
                                  checked={publish ? true : false}
                                  onChange={e => setPublish(true)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  Publish
                                </label>
                              </div>
                            </div>
                            <div>
                              <button
                                disabled={uploadStatus ? true : false}
                                type="submit"
                                className="btn btn-primary w-md mt-3"
                              >
                                Publish
                              </button>
                            </div>

                          </div>
                        </form>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddNewCourse.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

AddNewCourse.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(AddNewCourse)
