import React from "react"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function item({ blog, setStatus }) {
  const history = useHistory()

  const handleDelete = blogId => {
    fetch(`${process.env.REACT_APP_URL}/api/blog/delete/${blogId}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(() => {
        toastr.success("Course Deleted!!", "Course")
        setStatus(true)
      })
      .catch(err => console.log(err?.response?.data))
  }

  return (
    <tr>
      <td>
        <img
          src={
            blog?.upload_featured_image
              ? // eslint-disable-next-line no-undef
                `${process.env.REACT_APP_URL}/api/image/download/${blog?.upload_featured_image}`
              : ""
          }
          alt=""
          className="avatar-sm"
        />
      </td>
      <td>
        <h5 style={{ width: "300px" }} className="text-truncate font-size-14">
          <span style={{ fontSize: "20px" }} className="text-dark">
            {blog?.title}
          </span>
        </h5>
      </td>

      <td>{blog?.author_name}</td>
      <td>{blog?.published ? "true" : "false"}</td>
      {/* <td>
        <span>{instructor?.label}</span>
      </td> */}
      <td>
        <button
          style={{ marginRight: "10px" }}
          onClick={() => history.push(`/blogs/edit/${blog?._id}`)}
          className="btn btn-primary w-md"
        >
          Edit
        </button>
        <button
          onClick={() =>
            window.confirm("Do you want to delete this blog?")
              ? handleDelete(blog?._id)
              : null
          }
          className="btn btn-danger w-md"
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

export default item
