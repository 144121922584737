import React, { useEffect, useState } from "react"
import renderHTML from "react-render-html"
import moment from "moment"
import { getInstructors } from "actions/instructor"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function item({ coupon, setStatus, number }) {
  const [instructors, setInstructors] = useState([])

  const history = useHistory()

  const handleDelete = couponId => {
    fetch(`${process.env.REACT_APP_URL}/api/coupon/${couponId}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(() => {
        toastr.success("Coupon Deleted!!", "Coupon")
        setStatus(true)
      })
      .catch(err => console.log(err?.response?.data))
  }

  return (
    <tr>
      <td>{number + 1}</td>
      <td>{coupon?.code}</td>
      <td>{coupon?.discount ?? 0} %</td>
      <td>{coupon?.amount ?? 0} TK</td>
      <td>{moment(coupon?.expiry).format("ll")}</td>
      <td>{coupon?.maxuse}</td>
      <td>{coupon?.maxusePerPerson}</td>
      <td>{coupon?.active === true ? "true" : "false"}</td>
      <td>
        {/* <button
          style={{ marginRight: "10px" }}
          onClick={() => history.push(`/edit-coupon/${coupon?._id}`)}
          className="btn btn-primary w-md"
        >
          Edit
        </button> */}
        <button
          onClick={() => history.push(`/edit-coupon/${coupon?._id}`)}
          className="btn btn-primary w-md"
          style={{ marginRight: "10px" }}
        >
          Edit
        </button>
        <button
          onClick={() =>
            window.confirm("Do you want to delete this course?")
              ? handleDelete(coupon?._id)
              : null
          }
          className="btn btn-danger w-md"
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

export default item
