export const updateInfo = async content => {
  return await fetch(`${process.env.REACT_APP_URL}/api/information/update`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(content),
  }).then(res => {
    return res
  })
}

export const getInfo = async () => {
  return await fetch(`${process.env.REACT_APP_URL}/api/information/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}
