import React from "react"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { deleteNotice } from "actions/notice"

function item({ notice, setStatus, number }) {
  const history = useHistory()

  const handleDelete = noticeId => {
    deleteNotice(noticeId)
      .then(res => res.json())
      .then(() => {
        toastr.success("Notice Deleted!!", "Notice")
        setStatus(true)
      })
      .catch(err => console.log(err?.response?.data))
  }

  return (
    <tr>
      <td>{number + 1}</td>
      <td>{notice?.text}</td>
      <td>{notice?.visible === true ? "true" : "false"}</td>
      <td>
        {/* <button
          style={{ marginRight: "10px" }}
          onClick={() => history.push(`/edit-coupon/${coupon?._id}`)}
          className="btn btn-primary w-md"
        >
          Edit
        </button> */}
        <button
          onClick={() => history.push(`/edit-notice/${notice?._id}`)}
          className="btn btn-primary w-md"
          style={{ marginRight: "10px" }}
        >
          Edit
        </button>
        <button
          onClick={() =>
            window.confirm("Do you want to delete this course?")
              ? handleDelete(notice?._id)
              : null
          }
          className="btn btn-danger w-md"
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

export default item
