import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import { Col, Container, Row, Table, Button } from "reactstrap"
import "../Courses/course.scss"

import SeminarList from "./SeminarList"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"

const SeminarIndex = () => {
  const [seminarList, setSeminarList] = useState([])
  const [status, setStatus] = useState(false)

  const history = useHistory()

  // Get all courses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/seminar/get-all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setSeminarList(data.data)
       
      })
      .catch(err => console.log(err))
  }, [status])

  // delete specific course

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Seminar" breadcrumbItem="Seminar List" />
          <Button
            onClick={() => history.push(`/seminar/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Seminar
          </Button>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Seminar Name</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Zone</th>
                        <th scope="col">Type</th>

                        <th scope="col">Option</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(seminarList, (seminar, index) => (
                        <SeminarList
                          setStatus={setStatus}
                          status={status}
                          seminar={seminar}
                          key={index}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SeminarIndex)
