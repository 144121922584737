import React, { useEffect, useState } from "react"
import { Tr, Th, Td } from "react-super-responsive-table"
import { blockStudent, unBlockStudent } from "../../actions/auth"
import { Link } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function StudentCard({ student }) {
  const [block, setBlock] = useState(false)

  useEffect(() => {
    if (student) {
      if (student?.blocked === true) {
        setBlock(true)
      } else {
        setBlock(false)
      }
    }
  }, [student])

  //** Blocked Students */
  const handleBlock = () => {
    blockStudent(student?._id)
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setBlock(true)
          toastr.success(res?.message)
        }
      })
      .catch(err => console.log(err))
  }

  //** Unblocked Students */
  const handleUnblock = () => {
    unBlockStudent(student?._id)
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setBlock(false)
          toastr.success(res?.message)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <Tr>
      <Th>
        <Link to={`/student/${student?._id}`}>
          <span className="co-name">{student?.fullName}</span>
        </Link>
      </Th>
      <Td>{student?.email}</Td>
      <Td>{student?.phoneNumber}</Td>
      <Td>
        {block ? (
          <button onClick={handleUnblock} className="btn btn-primary">
            Unblock
          </button>
        ) : (
          <button onClick={handleBlock} className="btn btn-danger">
            Block
          </button>
        )}
      </Td>
    </Tr>
  )
}

export default StudentCard
