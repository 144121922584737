import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import { Col, Container, Row, Table, Button } from "reactstrap"
import "../Courses/course.scss"

import SubscriberList from "./SubscriberList"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"

const Subscribers = () => {
    const [subscriberList, setSubscriberList] = useState([])
    const [status, setStatus] = useState(false)

    const history = useHistory()

    // Get all courses
    useEffect(() => {
        fetch(`https://www.ourprofessors.com/new/api/subscriber/get-all`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(res => res.json())
            .then(data => {
                setSubscriberList(data.data)
                console.log({ data }, "subscriber");

            })
            .catch(err => console.log(err))
    }, [status])

    // delete specific course

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Our Professor</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Subscriber" breadcrumbItem="Subscriber List" />
                    {/* <Button
            onClick={() => history.push(`/subscriber/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Subscriber
          </Button> */}
                    <Row>
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Subscriber Email</th>
                                                <th scope="col">Date</th>
                                                {/* <th scope="col">Time</th>
                        <th scope="col">Zone</th>
                        <th scope="col">Type</th> */}

                                                {/* <th scope="col">Option</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(subscriberList, (subscriber, index) => (
                                                <SubscriberList

                                                    subscriber={subscriber?.email}
                                                    date={subscriber?.createdAt}
                                                    key={index}
                                                />
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Subscribers)
