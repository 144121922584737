import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"
import Item from "./Item"
import { Col, Container, Row, Table, Button } from "reactstrap"
// import "./course.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"

const Coupons = () => {
  const [couponList, setCouponList] = useState([])
  const [status, setStatus] = useState(false)

  const history = useHistory()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/coupon`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res?.json())
      .then(data => {
        setCouponList(data?.message)
        setStatus(false)
      })
      .catch(err => console.log(err))
  }, [status])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Coupons" breadcrumbItem="Coupons List" />
          <Button
            onClick={() => history.push(`/add-coupon/`)}
            color="primary"
            className="btn btn-primary"
          >
            Add New coupon
          </Button>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "100px" }}>
                          #
                        </th>
                        <th scope="col">Code</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Expiry date</th>
                        <th scope="col">Max use</th>
                        <th scope="col">Max use per person</th>
                        <th scope="col">Active status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(couponList, (coupon, index) => (
                        <Item
                          setStatus={setStatus}
                          coupon={coupon}
                          key={index}
                          number={index}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Coupons)
