import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { getAllStudents } from "actions/students"
import { withTranslation } from "react-i18next"

const SeminarStudent = props => {
  const [students, setStudents] = useState([])
  const [studentLoading, setStudentLoading] = useState(false)
  const [selectSeminar, setSelectSeminar] = useState(null)
  const [loading, setLoading] = useState(true)
  const [seminarList, setSeminarList] = useState([])
  const [totoalStudent, setTotalStudent] = useState("")
  const [change, setChange] = useState(false)

  const [status, setStatus] = useState(false)

  // Get all seminar
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/seminar/get-all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        console.log("all seminar", data)
        setSeminarList(data.data)
        setLoading(false)
      })
      .catch(err => console.log(err?.response?.data))
  }, [change])

  const handleStudent = stds => {
    console.log("students", stds);
    let students_data = []
    stds?.map(std => {
      students_data.push({
        name: std.name,
        email: std.email,
        phone: std.phone,
        question: std.question,
        seminarId: std.seminarId,
        _id: std._id
      })
    })

    console.log("student_data", students_data)
    setStudents(students_data)
  }

  useEffect(() => {
    setStudentLoading(true)
    fetch(
      `${process.env.REACT_APP_URL}/api/seminar-student/get/${selectSeminar}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(r => r.json())
      .then(res => {
        setStudentLoading(false)
        // setStudents(res?.data)
        handleStudent(res?.data)
        setTotalStudent(res?.meta?.total ? res?.meta?.total : 0)
      })
      .catch(err => console.log(err))
  }, [selectSeminar, change])

  const handleDelete = id => {
    fetch(`${process.env.REACT_APP_URL}/api/seminar-student/delete/${id}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(res => {
        toastr.success(res.message)
        setChange(!change)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    setLoading(true)
    {
      selectSeminar
        ? setLoading(false)
        : fetch(`${process.env.REACT_APP_URL}/api/seminar-student/get-all`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then(res => res.json())
            .then(data => {
              if (data?.success) {
                console.log(data)
                // setStudents(data?.data)
                handleStudent(data?.data)
                setTotalStudent(data?.meta?.total ? data?.meta?.total : 0)
                setStatus(false)
                setLoading(false)
              }
            })
            .catch(err => console.log(err))
    }
  }, [change])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Seminar")}
            breadcrumbItem={props.t("Seminar Students")}
          />

          <div className="mb-3">
            <Label htmlFor="formrow-firstname-Input">
              Select Seminar to get specific student list
            </Label>
            <Input
              name="seminar"
              type="select"
              autoComplete="off"
              onChange={e => setSelectSeminar(e.target.value)}
            >
              {seminarList?.map(seminar => {
                return (
                  <>
                    <option key={seminar._id} value="" hidden>
                      Select
                    </option>
                    <option id="seminarTitle" value={seminar._id}>
                      {seminar.title}
                    </option>
                  </>
                )
              })}
            </Input>
            {/* <button
              onClick={() => {
                setSelectSeminar(null)
                setChange(!change)
                setStatus(true)
               
              }}
              style={{
                marginTop: "10px",
                border: "none",
                background: "#2a3042",
                color: "white",
                padding: "5px 10px",
                borderRadius: "4px",
              }}
            >
              Clear Filter
            </button> */}
          </div>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      {loading ? (
                        <Spinner></Spinner>
                      ) : (
                        <>
                          <div className="d-flex">
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                                paddingBottom: "20px",
                              }}
                            >
                              <h5>
                                Total Student :{" "}
                                {totoalStudent ? totoalStudent : 0}
                              </h5>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                textAlign: "right",
                                paddingBottom: "20px",
                              }}
                            >
                              {students?.length > 0 && (
                                <CSVLink data={students}>Export as csv</CSVLink>
                              )}
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                            >
                              <Table className="project-list-table table-nowrap align-middle table-borderless">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th data-priority="1">Email</th>
                                    <th data-priority="3">Mobile Number</th>
                                    <th data-priority="3">Question</th>
                                    <th data-priority="3">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {students?.length > 0 &&
                                    students?.map((student, i) => {
                                      console.log(student)
                                      return (
                                        <tr key={i}>
                                          <td>{student.name}</td>
                                          <td>{student.email}</td>
                                          <td>{student.phone}</td>
                                          <td>{student.question}</td>
                                          <button
                                            onClick={() =>
                                              window.confirm(
                                                "Do you want to delete this student?"
                                              )
                                                ? handleDelete(student?._id)
                                                : null
                                            }
                                            className="btn btn-danger w-md"
                                          >
                                            Delete
                                          </button>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(SeminarStudent)
