import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card,
    CardBody,
    CardTitle, Col, Container,
    Row
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// React router dom

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next"

const ManualPayment = props => {
  const [manual, setManual] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/client/payment`)
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          if (res?.message === true) {
            setManual(res?.message)
          } else if (res?.message === false) {
            setManual(res?.message)
          }
        }
      })
      .catch(err => console.log(err))
  }, [])

  const handleSubmit = async () => {
    await fetch(`${process.env.REACT_APP_URL}/api/admin/payment`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          toastr.success("Manual payment updated")
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>Update Manual Payment </CardTitle>

                      <div>
                        <div className="my-4">
                          <h5 className="font-size-14 mb-4">
                            Set manual payment
                          </h5>
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="publish-status"
                              id="draft"
                              value="Draft"
                              checked={manual ? true : false}
                              onChange={e => setManual(true)}
                              defaultChecked
                            />
                            <label className="form-check-label" htmlFor="draft">
                              Manual True
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="publish-status"
                              id="Publish"
                              value="Publish"
                              checked={!manual ? true : false}
                              onChange={e => setManual(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Publish"
                            >
                              Manual False
                            </label>
                          </div>
                        </div>
                        <div>
                          <button
                            onClick={handleSubmit}
                            className="btn btn-primary w-md"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ManualPayment)
