import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card, CardBody, Col,
    Container, Form,
    Input, Label, Row
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import FBEditor from "components/Editor/Editor"
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { imageUpload } from "../../actions/imageUpload"
//redux

const EditBook = props => {
  const { id } = useParams()
  const [bookName, setBookName] = useState(null)
  const [slug, setSlug] = useState(null)
  const [bookShortDetails, setBookShortDetails] = useState(null)
  const [bookDetails, setBookDetails] = useState(null)
  const [writerName, setWriterName] = useState(null)
  const [bookCover, setBookCover] = useState(null)
  const [bookFile, setBookFile] = useState(null)
  const [authorDetails, setAuthorDetails] = useState(null)
  const [authorImageError, setAuthorImageError] = useState(null)
  const [bookImageError, setBookImageError] = useState(null)
  const [bookFileError, setBookFileError] = useState(null)
  const [authorImage, setAuthorImage] = useState(null)
  const [paid, setPaid] = useState(true)
  const [publish, setPublish] = useState(true)
  const [regularPrice, setRegularPrice] = useState(0)
  const [salePrice, setSalePrice] = useState(0)

  const history = useHistory()
  const [getBookDetails, setGetBookDetails] = useState([])
  const [uploadStatus, setUploadStatus] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/book/get/${id}`, { method: "GET" })
      .then(res => res.json())
      .then(data => setGetBookDetails(data?.message))
      .catch(err => console.log(err))
    // setAddStatus(false)
  }, [id])

  useEffect(() => {
    setBookName(getBookDetails?.book_name)
    setBookShortDetails(getBookDetails?.book_short_details)
    setBookDetails(getBookDetails?.book_details)
    setWriterName(getBookDetails?.author_name)
    setBookCover(getBookDetails?.book_cover)
    setBookFile(getBookDetails?.book_file)
    setAuthorDetails(getBookDetails?.author_description)
    setAuthorImage(getBookDetails?.author_image)
    setRegularPrice(getBookDetails?.regular_price)
    setSalePrice(getBookDetails?.sale_price)
    setPaid(getBookDetails?.book_paid)
    setPublish(getBookDetails?.book_publish)
    setSlug(getBookDetails?.slug)
  }, [getBookDetails])

  const handleSubmit = async e => {
    e.preventDefault()
    await fetch(`${process.env.REACT_APP_URL}/api/book/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        book_name: bookName,
        book_short_details: bookShortDetails,
        book_details: bookDetails,
        author_name: writerName,
        book_cover: bookCover,
        book_file: bookFile,
        author_image: authorImage,
        author_description: authorDetails,
        regular_price: regularPrice,
        sale_price: salePrice,
        book_paid: paid,
        book_publish: publish,
        slug,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data?.success) {
          toastr.error(data?.message)
        } else if (data?.success) {
          toastr.success(data?.message)
          history.push("/book")
        }
      })
      .catch(err => console.log(err))
  }

  const handleBookCoverChange = e => {
    setBookImageError(null)
    const extension = e.target.files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(e.target.files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setBookCover(data?.key)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              e.target.value = ""
              setUploadStatus(false)
              toastr.error(data?.message, "Book Image")
            } else {
              setBookCover(data?.key)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Book Image")
          } else {
            toastr.error(err?.response?.data?.message, "Book Image")
          }
        })
    } else {
      e.target.value = ""
      setBookImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  const handleBookFileChange = async e => {
    setBookFileError(null)
    const extension = e.target.files[0]?.name?.split(".").pop()

    if (
      extension === "PDF" ||
      extension === "pdf" ||
      extension === "zip" ||
      extension === "ZIP"
    ) {
      setUploadStatus(true)
      const f = e.target.files[0]

      await fetch(
        `${process.env.REACT_APP_URL}/api/largeFile/upload/generatePresignedURL/${extension}`,
        { method: "GET" }
      )
        .then(r => r.json())
        .then(res => {
          setBookFile(res?.Key)
          fetch(res.uploadURL, {
            method: "PUT",
            body: f,
          }).then(() => {
            setUploadStatus(false)
          })
        })
    } else {
      e.target.value = ""
      setBookFileError("Unknown type! try to select pdf or zip type!")
    }
  }

  const handleAuthorImageChange = e => {
    setAuthorImageError(null)
    const extension = e.target.files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(e.target.files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setAuthorImage(data?.key)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              e.target.value = ""
              setUploadStatus(false)
              toastr.error(data?.message, "Author Image")
            } else {
              setAuthorImage(data?.key)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Author Image")
          } else {
            toastr.error(err?.response?.data?.message, "Author Image")
          }
        })
    } else {
      e.target.value = ""
      setAuthorImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Book")}
            breadcrumbItem={props.t("Update book")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Book Name
                            </Label>
                            <Input
                              value={bookName}
                              onChange={e => setBookName(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              required
                              placeholder="You Can Win"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Slug
                            </Label>
                            <Input
                              required
                              value={slug}
                              onChange={e => setSlug(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Book Short Details
                            </Label>
                            <Input
                              value={bookShortDetails}
                              onChange={e =>
                                setBookShortDetails(e.target.value)
                              }
                              required
                              type="textarea"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Write short brief about book..."
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Book Details
                            </Label>
                            <FBEditor
                              htmlData={bookDetails}
                              setHtmlData={setBookDetails}
                              prevData={getBookDetails?.book_details}
                            />
                          </div>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">
                                  Regular Price
                                </Label>
                                <Input
                                  required
                                  onWheel={e => e.target.blur()}
                                  value={regularPrice}
                                  onChange={e =>
                                    setRegularPrice(e.target.value)
                                  }
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  id="formrow-email-Input"
                                  placeholder="0"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-password-Input">
                                  Sale Price
                                </Label>
                                <Input
                                  required
                                  onWheel={e => e.target.blur()}
                                  value={salePrice}
                                  onChange={e => setSalePrice(e.target.value)}
                                  type="number"
                                  min="0"
                                  className="form-control"
                                  id="formrow-password-Input"
                                  placeholder="0"
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Author Name
                            </Label>
                            <Input
                              value={writerName}
                              onChange={e => setWriterName(e.target.value)}
                              required
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Mr. XYZ"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Author Details
                            </Label>
                            <Input
                              required
                              value={authorDetails}
                              onChange={e => setAuthorDetails(e.target.value)}
                              type="textarea"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Write something about author..."
                            />
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Author Image
                            </Label>
                            <Input
                              onChange={e => handleAuthorImageChange(e)}
                              type="file"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                            {authorImageError && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              >
                                {authorImageError}
                              </span>
                            )}

                            {getBookDetails !== null && (
                              <div className="mt-3">
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    authorImage
                                      ? `${process.env.REACT_APP_URL}/api/image/download/${authorImage}`
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Upload Cover Image
                            </Label>
                            <Input
                              onChange={e => handleBookCoverChange(e)}
                              type="file"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                            {bookImageError && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              >
                                {bookImageError}
                              </span>
                            )}
                            {getBookDetails !== null && (
                              <div className="mt-3">
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    bookCover
                                      ? `${process.env.REACT_APP_URL}/api/image/download/${bookCover}`
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Upload Book File
                            </Label>
                            <Input
                              onChange={e => handleBookFileChange(e)}
                              type="file"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                            {bookFileError && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              >
                                {bookFileError}
                              </span>
                            )}

                            <div style={{ marginTop: "20px" }}>
                              {getBookDetails !== null && bookFile && (
                                <a
                                  href={
                                    bookFile
                                      ? `${process.env.REACT_APP_URL}/api/book/download/${bookFile}`
                                      : ""
                                  }
                                  target="_blank"
                                  className="btn btn-primary w-md"
                                  download
                                >
                                  Book File Download
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">
                              Free/Paid Status
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="free-paid-status"
                                id="free"
                                value="Free"
                                checked={!paid ? true : false}
                                onChange={e => setPaid(false)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="free"
                              >
                                Free
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="free-paid-status"
                                id="paid"
                                value="Paid"
                                onChange={e => setPaid(true)}
                                checked={paid ? true : false}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="paid"
                              >
                                Paid
                              </label>
                            </div>
                          </div>
                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">
                              Publish Status
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="publish-status"
                                id="draft"
                                value="Draft"
                                checked={!publish ? true : false}
                                onChange={e => setPublish(false)}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="draft"
                              >
                                Draft
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="publish-status"
                                id="Publish"
                                value="Publish"
                                checked={publish ? true : false}
                                onChange={e => setPublish(true)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Publish"
                              >
                                Publish
                              </label>
                            </div>
                          </div>

                          <div>
                            <button
                              disabled={uploadStatus ? true : false}
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Update
                            </button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditBook.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

EditBook.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(EditBook)
