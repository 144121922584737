import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import { Col, Container, Row, Table, Button } from "reactstrap"
import "../Courses/course.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const SupportList = () => {
  const [supportList, setsupportList] = useState([])
  const [status, setStatus] = useState(false)
  const [solvedList, setSolvedList] = useState(null)
  const [unSolvedList, setUnSolvedList] = useState(null)

  const history = useHistory()

  // Get all support tickets
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/admin/support/get-all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setsupportList(data.data)
        handleSolvedUnsolvedSupport(data.data)
      })
      .catch(err => console.log(err))
  }, [status])

  // console.log(supportList)
  // console.log(status);

  const handleSolvedUnsolvedSupport = data => {
    let solvedList = []
    let unSolvedList = []

    data?.map(d => {
      if (d.solved) {
        solvedList.push(d)
      } else {
        unSolvedList.push(d)
      }
    })

    console.log("solve", solvedList)
    setSolvedList(solvedList)
    console.log("unsolve", unSolvedList)
    setUnSolvedList(unSolvedList)
  }

  // delete specific course
  const handleDelete = supportTicketId => {
    fetch(
      `${process.env.REACT_APP_URL}/api/admin/support/delete/${supportTicketId}`,
      {
        method: "DELETE",
      }
    )
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          toastr.success(res?.message, "Support")
          setStatus(!status)
        } else {
          toastr.error(res?.message, "Support")
        }
      })
      .catch(err => console.log(err))
  }

  const handleSolveToogle = supportTicketId => {
    fetch(
      `${process.env.REACT_APP_URL}/api/admin/support/toggle-solve-status/${supportTicketId}`,
      {
        method: "PUT",
      }
    )
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          toastr.success(res?.message, "Support")
          setStatus(!status)
        } else {
          toastr.error(res?.message, "Support")
        }

        // console.log(res)
        // setStatus(!status)
      })
      .catch(err => console.log(err))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Our Professor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Support" breadcrumbItem="Support List" />
          {unSolvedList?.length > 0 ? (
            <>
              <h5>Unsolved Support Ticket</h5>
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Subject</th>
                            <th scope="col">Message</th>
                            <th scope="col">Option</th>
                          </tr>
                        </thead>
                        <tbody>
                          {unSolvedList?.map((support, index) => {
                            return (
                              <tr key={index}>
                                <td>{support?.subject}</td>
                                <td>{support?.message}</td>

                                <td>
                                  <button
                                    style={{ marginRight: "10px" }}
                                    onClick={() =>
                                      history.push(
                                        `/supportDetails/${support?._id}`
                                      )
                                    }
                                    className="btn btn-primary w-md"
                                  >
                                    Reply
                                  </button>
                                  <button
                                    onClick={() =>
                                      window.confirm(
                                        "Do you want to delete this support ticket?"
                                      )
                                        ? handleDelete(support?._id)
                                        : null
                                    }
                                    className="btn btn-danger w-md"
                                  >
                                    Delete
                                  </button>

                                  <button
                                    onClick={() =>
                                      window.confirm(
                                        "Do you want to mark it as solved?"
                                      )
                                        ? handleSolveToogle(support?._id)
                                        : null
                                    }
                                    className="btn btn-success w-md ms-2"
                                  >
                                    Solved
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>{" "}
            </>
          ) : null}

          {solvedList?.length > 0 ? (
            <>
              {" "}
              <h5>Solved Support Ticket</h5>
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Subject</th>
                            <th scope="col">Message</th>
                            <th scope="col">Previous Conversation</th>
                            <th scope="col">Option</th>
                          </tr>
                        </thead>
                        <tbody>
                          {solvedList?.map((support, index) => {
                            return (
                              <tr key={index}>
                                <td>{support?.subject}</td>
                                <td>{support?.message}</td>

                                <td>
                                  <button
                                    style={{ marginRight: "10px" }}
                                    onClick={() =>
                                      history.push(
                                        `/supportDetails/${support?._id}`
                                      )
                                    }
                                    className="btn btn-primary w-md"
                                  >
                                    Watch
                                  </button>
                                </td>
                                <td>
                                  <button
                                    onClick={() =>
                                      window.confirm(
                                        "Do you want to delete this support ticket?"
                                      )
                                        ? handleDelete(support?._id)
                                        : null
                                    }
                                    className="btn btn-danger w-md"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>{" "}
            </>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SupportList)
