import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Button, Card,
    CardBody, CardSubtitle, CardTitle, Col, Container,
    Row
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// React router dom
import { useHistory } from "react-router"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { getInstructors } from "actions/instructor"
import { withTranslation } from "react-i18next"

const Instructors = props => {
  const [instructors, setInstructors] = useState([])
  const [status, setStatus] = useState(false)
  const history = useHistory()

  useEffect(() => {
    getInstructors()
      .then(r => r.json())
      .then(res => {
        if (res?.success) {
          setInstructors(res?.message)
          setStatus(false)
        }
      })
      .catch(err => console.log(err))
  }, [status])

  const handleDelete = id => {
    fetch(`${process.env.REACT_APP_URL}/api/instructor/delete/${id}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(r => {
        if (r?.success) {
          toastr.success(r?.message, "Instructor")
          setStatus(true)
        }
      })
      .catch(err => console.log(err?.response?.data))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Instructors")}
            breadcrumbItem={props.t("Instructors")}
          />
          <Button
            onClick={() => history.push(`/instructors/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Instructor
          </Button>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>All Instructors List </CardTitle>
                      <CardSubtitle className="mb-3">
                        total {instructors?.length} instructors
                      </CardSubtitle>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Full Name</Th>
                                <Th data-priority="1">Email</Th>
                                <Th data-priority="2">Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {instructors?.map(ins => (
                                <Tr>
                                  <Th>
                                    <span className="co-name">
                                      {ins?.fullName}
                                    </span>
                                  </Th>
                                  <Td>{ins?.email}</Td>
                                  <Td>
                                    <Button
                                      onClick={() =>
                                        window.confirm(
                                          "Do you want to delete this instructor?"
                                        )
                                          ? handleDelete(ins?._id)
                                          : null
                                      }
                                      className="btn btn-danger w-md"
                                    >
                                      Delete
                                    </Button>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Instructors)
