import React, { useState, useEffect } from "react"
import { Modal, Card, CardBody, Row, Col, Label, Input } from "reactstrap"
import { UncontrolledTooltip } from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { bookUpload } from "../../actions/imageUpload"
import { lessonDragAndDrop } from "actions/course"
import FBEditor from "components/Editor/Editor"

function CurriculumLesson({
  lessons,
  setReArrange,
  reArrange,
  setLessons,
  addStatus,
  setAddStatus,
  sectionId,
  les,
  handleDeleteLesson,
  index,
}) {
  const [view, setView] = useState(false)
  const [edit, setEdit] = useState(false)
  const [title, setTitle] = useState("")
  const [details, setDetails] = useState(null)
  const [links, setLinks] = useState(null)
  const [attachmentName, setAttachmentName] = useState(null)
  const [attachment, setAttachment] = useState(null)
  const [preview, setPreview] = useState(false)
  const [attachmentError, setAttachmentError] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)

  const [downloadUrl, setDownloadUrl] = useState(null)

  // Lesson Details
  const [lessonDetails, setLessonDetails] = useState(null)

  const handleBodyChange = e => {
    setDetails(e)
  }

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/api/lesson/get/${sectionId}/${les?._id}`,
      {
        method: "GET",
      }
    )
      .then(res => res.json())
      .then(data => setLessonDetails(data?.message?.lessons[0]))
      .catch(err => console.log(err))
  }, [sectionId, les, addStatus])

  useEffect(() => {
    setTitle(lessonDetails?.title)
    setDetails(lessonDetails?.description ? lessonDetails?.description : "")
    setLinks(lessonDetails?.url)
    setAttachment(lessonDetails?.attatchment)
    setAttachmentName(lessonDetails?.attatchment_name)
    setPreview(lessonDetails?.preview === null ? false : lessonDetails?.preview)
  }, [lessonDetails])

  const handleLessonUpdate = () => {
    const body = {
      lesson_title: title,
      lesson_duration: "",
      lesson_url: links,
      lesson_description: details,
      lesson_attatchment: attachment,
      attatchment_name: attachmentName,
      lesson_preview: preview,
    }
    fetch(
      `${process.env.REACT_APP_URL}/api/lesson/update/${sectionId}/${les?._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => res.json())
      .then(data => {
        toastr.success(data?.message, "Lesson")
        setAddStatus(true)
      })
      .catch(err => console.log(err))

    setEdit(false)
  }

  const handleAttachment = async e => {
    setAttachmentError(null)
    const extension = e.target.files[0]?.name?.split(".").pop()
    console.log(extension)

    if (
      extension === "PDF" ||
      extension === "pdf" ||
      extension === "zip" ||
      extension === "ZIP"
    ) {
      setUploadStatus(true)
      const f = e.target.files[0]

      await fetch(
        `${process.env.REACT_APP_URL}/api/largeFile/upload/generatePresignedURL/${extension}`,
        { method: "GET" }
      )
        .then(r => r.json())
        .then(res => {
          console.log({ res })
          setAttachment(res?.Key)

          fetch(res.uploadURL, {
            method: "PUT",
            body: f,
          }).then(() => {
            setUploadStatus(false)
          })
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      e.target.value = ""
      setAttachmentError("Unknown type! try to select pdf or zip type!")
    }
  }

  useEffect(() => {
    if (lessonDetails && lessonDetails?.attatchment) {
      const downloadF = async () => {
        await fetch(
          `${process.env.REACT_APP_URL}/api/largeFile/download/generatePresignedURL/${lessonDetails?.attatchment}`,
          {
            method: "GET",
          }
        )
          .then(res => res.json())
          .then(data => {
            setDownloadUrl(data?.uploadURL)
          })
          .catch(err => console.log(err))
      }
      downloadF()
    }
  }, [lessonDetails])

  const handleDrag = (e, index) => {
    // console.log("Drag Start", index)
    e.dataTransfer.setData("itemIndex", index)
  }

  const handleDrop = (e, index) => {
    const movingItemIndex = Number(e.dataTransfer.getData("itemIndex"))
    const targetIndex = index

    let allLessons = lessons

    let movingItem = allLessons[movingItemIndex]
    allLessons.splice(movingItemIndex, 1)

    allLessons.splice(targetIndex, 0, movingItem)

    setLessons(allLessons)
    setReArrange(!reArrange)

    lessonDragAndDrop(allLessons, sectionId)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div
      draggable
      onDragStart={e => handleDrag(e, index)}
      onDrop={e => handleDrop(e, index)}
      className="curriculum__section__wrapper"
    >
      <div className="curriculum__section__title">
        <div className="hambar__title">
          <span className="hambar">
            <i className="bx bx-menu"></i>
          </span>
          <span className="title">{les?.title}</span>
        </div>
        <div style={{ display: "flex", marginRight: "20px" }}>
          <div onClick={() => setEdit(true)} className="flex-fill">
            <span
              style={{ marginLeft: "10px", fontSize: "20px" }}
              id={"edit" + les?._id}
            >
              <i className="bx bxs-edit"></i>
              <UncontrolledTooltip placement="top" target={"edit" + les?._id}>
                Edit
              </UncontrolledTooltip>
            </span>
          </div>
          <div onClick={() => setView(true)} className="flex-fill">
            <span
              style={{ marginLeft: "10px", fontSize: "20px" }}
              id={"view" + les?._id}
            >
              <i className="bx bxs-show"></i>
              <UncontrolledTooltip placement="top" target={"view" + les?._id}>
                View
              </UncontrolledTooltip>
            </span>
          </div>

          <div className="flex-fill">
            <span
              onClick={() =>
                window.confirm("Do you want to delete this lesson?")
                  ? handleDeleteLesson(les?._id)
                  : null
              }
              style={{ marginLeft: "10px", fontSize: "20px" }}
              id={"delete" + les?._id}
            >
              <i className="bx bx-trash"></i>
              <UncontrolledTooltip placement="top" target={"delete" + les?._id}>
                Delete
              </UncontrolledTooltip>
            </span>
          </div>
        </div>
      </div>

      {/* Edit Lesson */}

      <Modal size="lg" isOpen={edit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {les?.title}
          </h5>
          <button
            onClick={() => {
              setEdit(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">Title</Label>
                  <Input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Course Title"
                  />
                </div>
                <Row>
                  <Col
                    lg={12}
                    style={{ marginTop: "0px", marginBottom: "10px" }}
                  >
                    <FBEditor
                      htmlData={details}
                      setHtmlData={setDetails}
                      prevData={
                        lessonDetails?.description
                          ? lessonDetails?.description
                          : ""
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Media</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      value={links}
                      onChange={e => setLinks(e.target.value)}
                      placeholder="Add an embed link"
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Attachment Name</Label>

                    <Input
                      type="text"
                      id="textarea"
                      value={attachmentName}
                      onChange={e => setAttachmentName(e.target.value)}
                      placeholder="Attachment name"
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Attachment</Label>

                    <Input
                      type="file"
                      className="form-control"
                      id="formrow-firstname-Input"
                      onChange={e => handleAttachment(e)}
                    />
                  </div>
                  {lessonDetails?.attatchment && (
                    <Row>
                      <div className="mt-3">
                        <div>
                          <a
                            style={{
                              background: "#38aba1",
                              display: "inline-block",
                              color: "white",
                              padding: "10px 20px",
                              borderRadius: "6px",
                            }}
                            href={downloadUrl}
                            download
                          >
                            Download attachment
                          </a>
                        </div>
                      </div>
                    </Row>
                  )}
                  {attachmentError && (
                    <span
                      style={{
                        display: "block",
                        marginTop: "10px",
                        color: "red",
                      }}
                    >
                      {attachmentError}
                    </span>
                  )}
                </Row>
                <Row>
                  <div
                    style={{ marginLeft: "12px" }}
                    className="form-check form-switch form-switch-lg my-3"
                  >
                    <label
                      className="form-check-label"
                      htmlFor="customSwitchsizelg"
                    >
                      Lesson Preview
                    </label>
                    <input
                      value={preview}
                      onChange={e => setPreview(e.target.checked)}
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizelg"
                      defaultChecked={preview ? true : false}
                    />
                  </div>
                </Row>
                <div style={{ marginTop: "20px" }}>
                  <button
                    disabled={uploadStatus ? true : false}
                    onClick={handleLessonUpdate}
                    className="btn btn-primary w-md"
                  >
                    Save
                  </button>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Modal>

      {/* View Lesson */}

      <Modal size="lg" isOpen={view}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {les?.title}
          </h5>
          <button
            onClick={() => {
              setView(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">Title</Label>
                  <Input
                    value={title}
                    readOnly
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Course Title"
                  />
                </div>
                <Row>
                  <Col
                    lg={12}
                    style={{ marginTop: "0px", marginBottom: "10px" }}
                  >
                    <FBEditor
                      htmlData={details}
                      setHtmlData={setDetails}
                      prevData={
                        lessonDetails?.description
                          ? lessonDetails?.description
                          : ""
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Media</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      value={links}
                      readOnly
                      placeholder="Add an embed link"
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mt-3">
                    <Label>Attachment Name</Label>

                    <Input
                      type="text"
                      id="textarea"
                      value={attachmentName}
                      readOnly={true}
                      placeholder="Attachment name"
                    />
                  </div>
                </Row>

                {lessonDetails?.attatchment && (
                  <Row>
                    <div className="mt-3">
                      <Label>Attachment</Label>

                      <div>
                        <a
                          style={{
                            background: "#38aba1",
                            display: "inline-block",
                            color: "white",
                            padding: "10px 20px",
                            borderRadius: "6px",
                          }}
                          href={downloadUrl}
                          download
                        >
                          Download attachment
                        </a>
                      </div>
                    </div>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

CurriculumLesson.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

CurriculumLesson.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default CurriculumLesson
