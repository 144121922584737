import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import {
    Card, CardBody, Col,
    Container, Input, Label, Row
} from "reactstrap"

//i18n
import { imageUpload } from "actions/imageUpload"
import FBEditor from "components/Editor/Editor"
import { withTranslation } from "react-i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const EditBlog = props => {
  const [blogDetails, setBlogDetails] = useState(null)
  const [addStatus, setAddStatus] = useState(false)
  const history = useHistory()
  /**
   * * State for course data
   */
  const [title, setTitle] = useState("")
  const [slug, setSlug] = useState("")
  const [authorName, setAuthorName] = useState("")
  const [details, setDetails] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [profileImageError, setProfileImageError] = useState(null)
  const [publish, setPublish] = useState(true)
  const [image, setImage] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/blog/get/${id}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => setBlogDetails(data?.message))
      .catch(err => console.log(err))
    setAddStatus(false)
  }, [id, addStatus])

  //** Get the blog data */
  useEffect(() => {
    setTitle(blogDetails !== null ? blogDetails?.title : "")
    setAuthorName(blogDetails !== null ? blogDetails?.author_name : "")
    setDetails(
      blogDetails !== null
        ? blogDetails?.post_description !== null
          ? blogDetails?.post_description
          : ""
        : ""
    )
    setImage(blogDetails !== null && blogDetails?.upload_featured_image)
    setPublish(blogDetails !== null && blogDetails?.published)
    setSlug(blogDetails !== null ? blogDetails?.slug : "")
  }, [blogDetails])

  function handleAcceptedFiles(files) {
    setProfileImageError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setImage(data?.key)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setImage(data?.key)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setProfileImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  const handleUpdate = async () => {
    const body = {
      title,
      slug,
      post_description: details,
      author_name: authorName,
      upload_featured_image: image,
      published: publish,
    }

    await fetch(`${process.env.REACT_APP_URL}/api/blog/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Blog")
          history.push("/blogs")
        } else {
          toastr.error(data?.message, "Blog")
        }
      })
      .catch(err => toastr.error(err?.response?.data?.message, "Blog"))
  }
  const handleBodyChange = e => {
    setDetails(e)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Blog Title
                            </Label>
                            <Input
                              required
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Blog Title"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Slug
                            </Label>
                            <Input
                              required
                              minLength={6}
                              value={slug}
                              onChange={e => setSlug(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>

                          <Row>
                            <Col
                              lg={12}
                              style={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                            >
                              <FBEditor
                                htmlData={details}
                                setHtmlData={setDetails}
                                prevData={
                                  blogDetails !== null
                                    ? blogDetails?.post_description !== null
                                      ? blogDetails?.post_description
                                      : ""
                                    : ""
                                }
                              />
                            </Col>
                          </Row>

                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Author Name
                            </Label>
                            <Input
                              required
                              value={authorName}
                              onChange={e => setAuthorName(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Author Name"
                            />
                          </div>

                          <div>
                            <label htmlFor="">Upload Featured Image</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input required {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            {profileImageError && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              >
                                {profileImageError}
                              </span>
                            )}
                            {image && (
                              <div className="mt-3">
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    image
                                      ? `${process.env.REACT_APP_URL}/api/image/download/${image}`
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                          </div>

                          <div className="my-4">
                            <h5 className="font-size-14 mb-4">
                              Publish Status
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="publish-status"
                                id="draft"
                                value="Draft"
                                checked={!publish ? true : false}
                                onChange={e => setPublish(false)}
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="draft"
                              >
                                Draft
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="publish-status"
                                id="Publish"
                                value="Publish"
                                checked={publish ? true : false}
                                onChange={e => setPublish(true)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Publish"
                              >
                                Publish
                              </label>
                            </div>
                          </div>

                          <div>
                            <button
                              disabled={uploadStatus ? true : false}
                              onClick={handleUpdate}
                              className="btn btn-primary w-md mt-3"
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditBlog.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

EditBlog.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(EditBlog)
