export const getSpecificCourse = async courseId => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/course/get/${courseId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}

export const getSpecificBundleCourse = async courseId => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/bundle/get/${courseId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}

//** Blocked Course */

export const blockedCourse = async (courseId, userId) => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/students/course/blocked`,
    {
      method: "POST",
      body: JSON.stringify({
        course_id: courseId,
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}

//** Unblocked Course */

export const unBlockedCourse = async (courseId, userId) => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/students/course/unblocked`,
    {
      method: "POST",
      body: JSON.stringify({
        course_id: courseId,
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}

//** Check course blocked or not */

export const checkCourseBlocked = async (courseId, userId) => {
  return await fetch(
    `${process.env.REACT_APP_URL}/api/students/course/isblocked`,
    {
      method: "POST",
      body: JSON.stringify({
        course_id: courseId,
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then(res => {
    return res
  })
}

//** Lessons Drag and Drop functionality  */

export const lessonDragAndDrop = async (lessons, sectionId) => {
  return await fetch(`${process.env.REACT_APP_URL}/api/lesson/drag-drop`, {
    method: "POST",
    body: JSON.stringify({
      lessons,
      chapter_id: sectionId,
    }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}

//** Section Drag and Drop functionality  */

export const sectionDragAndDrop = async (chapters, courseId) => {
  return await fetch(`${process.env.REACT_APP_URL}/api/chapter/drag-drop`, {
    method: "POST",
    body: JSON.stringify({
      chapters,
      course_id: courseId,
    }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    return res
  })
}
